import React from "react";
import { Td, Tr } from "@chakra-ui/react";

const PaymentCardDetail = ({ item }) => {
	return (
		<>
			<Tr>
				<Td fontSize={{ base: "0.5rem", sm: "0.6rem", md: "0.7rem", lg: "0.9rem", xl: "1.1rem", "2xl": "1.4rem" }}>
					{item["card"]["brand"]}
				</Td>
				<Td fontSize={{ base: "0.5rem", sm: "0.6rem", md: "0.7rem", lg: "0.9rem", xl: "1.1rem", "2xl": "1.4rem" }}>
					{item["card"]["exp_month"]}
				</Td>
				<Td fontSize={{ base: "0.5rem", sm: "0.6rem", md: "0.7rem", lg: "0.9rem", xl: "1.1rem", "2xl": "1.4rem" }}>
					{item["card"]["exp_year"]}
				</Td>
				<Td fontSize={{ base: "0.5rem", sm: "0.6rem", md: "0.7rem", lg: "0.9rem", xl: "1.1rem", "2xl": "1.4rem" }}>
					{item["card"]["last4"]}
				</Td>
			</Tr>
		</>
	);
};

export default PaymentCardDetail;
