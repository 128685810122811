import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    loading: false,
    error: null,
    inboundDocReqEvents: [],
    inboundDocReqWorkingEvents: [],
    inboundDocReqIndexedEvents: {},
    inboundDocReqPagiEvents: [],
    pagination: {
        'eventsPerPage': 10,
        'currentPage': 1,
        'totalPages': 0
    },
    curCreatedDateOrder: null,
    curDiscrepancyOrder: null
};

export const userInboundSlice = createSlice({
    name: 'userInbound',
    initialState,
    reducers: {
        setLoading: (state) => {
            state.error = null;
            state.loading = true;
        },
        setError: (state, { payload }) => {
            state.error = payload;
            state.loading = false;
        },
        setInboundDocReqEvents: (state, { payload }) => {
            state.error = null;
            state.loading = false;
            state.inboundDocReqEvents = payload;
        },
        setInboundDocReqWorkingEvents: (state, { payload }) => {
            state.error = null;
            state.loading = false;
            state.inboundDocReqWorkingEvents = payload;
        },
        setInboundDocReqIndexedEvents: (state, { payload }) => {
            state.error = null;
            state.loading = false;
            state.inboundDocReqIndexedEvents = payload;
        },
        setInboundDocReqPagiEvents: (state, { payload }) => {
            state.error = null;
            state.loading = false;
            state.inboundDocReqPagiEvents = payload;
        },
        setPagination: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.pagination = payload;
        },
        setCurCreatedDateOrder: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.curDiscrepancyOrder = null;
            state.curCreatedDateOrder = payload;
        },
        setCurDiscrepancyOrder: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.curDiscrepancyOrder = payload;
            state.curCreatedDateOrder = null;
        },
        setDocUploaded: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.inboundDocReqEvents[payload].docUploaded = true;
        },
        setDocRequired: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.inboundDocReqEvents[payload.index].docRequired = payload.val;
        },
    },
});

// The followings are actually generated action creators, not actions themselves.
// Action creators and action types are generated for each case reducer function.
export const {
    setLoading,
    setError,
    setInboundDocReqEvents,
    setInboundDocReqIndexedEvents,
    setInboundDocReqPagiEvents,
    setInboundDocReqWorkingEvents,
    setCurCreatedDateOrder,
    setCurDiscrepancyOrder,
    setDocUploaded,
    setDocRequired,
    setPagination
} = userInboundSlice.actions;

export default userInboundSlice.reducer;