import React from "react";
import { Td, Tr, Input, VStack, Button, Text, useToast, Select } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";

const AdminClientCaseTableItem = ({ singleCase }) => {
	const { workingCases } = useSelector((state) => state.adminClientCase);
	return (
		<Tr fontSize='1vw'>
			<Td>
				<Text>{workingCases[singleCase.index].dateFiled}</Text>
			</Td>
			<Td>
				<Text>{workingCases[singleCase.index].caseID}</Text>
			</Td>
			<Td>
				<Text>{workingCases[singleCase.index].storeName}</Text>
			</Td>
			{/* status */}
			<Td>
				<Select
					w={{ base: "15em", md: "15em", lg: "15em" }}
					placeholder='Select Status'
					id='eventStatus'
					style={{ fontSize: "1vw", fontFamily: "Arial", fontWeight: "bold" }}
					value={workingCases[singleCase.index].status}
					// onChange={(e) => {
					//     dispatch(setEventStatus({ idx: singleEvent.idx, eventStatus: e.target.value }));
					//     dispatch(setEventIsUnsaved(singleEvent.idx));
					// }}
				>
					<option value='Negotiating'>STATUS (NEGOTIATING)</option>
					<option value='Reimbursed (Value TBD)'>STATUS (REIMBURSED-VALUE TBD)</option>
					<option value='Reimbursed'>STATUS (REIMBURSED)</option>
					<option value='Closed'>STATUS (CLOSED)</option>
				</Select>
			</Td>
			{/* Reimbursement ID */}
			<Td>
				<Input
					id='caseId'
					w={{ base: "9em", md: "9em", lg: "9em" }}
					value={workingCases[singleCase.index].reimbID}
					// onChange={(e) => {
					//     dispatch(setCaseID({ idx: singleEvent.idx, caseID: e.target.value }));
					//     dispatch(setEventIsUnsaved(singleEvent.idx));
					// }}
				/>
			</Td>
			{/* quantity reimbursed */}
			<Td>
				<Input
					id='quantityReimb'
					w={{ base: "6em", md: "6em", lg: "6em" }}
					value={workingCases[singleCase.index].quantity}
					// onChange={(e) => {
					//     dispatch(setQuantity({ idx: singleEvent.idx, quantityReimb: e.target.value }));
					//     dispatch(setEventIsUnsaved(singleEvent.idx));
					// }}
				/>
			</Td>
			{/* value reimbursed */}
			<Td>
				<Input
					id='valueReimb'
					w={{ base: "6em", md: "6em", lg: "6em" }}
					value={workingCases[singleCase.index].value}
					// onChange={(e) => {
					//     dispatch(setValue({ idx: singleEvent.idx, valueReimb: e.target.value }));
					//     dispatch(setEventIsUnsaved(singleEvent.idx));
					// }}
				/>
			</Td>
			<Td>
				<VStack>
					<Button
						id='saveButton'
						colorScheme='green'
						size='xs'
						variant='outline'
						// onClick={saveButtonClicked}
						// isDisabled={isSaved ? true : false}
					>
						{/* {isSaved ? "Saved" : "Save Changes"} */}
					</Button>
				</VStack>
			</Td>
		</Tr>
	);
};

export default AdminClientCaseTableItem;
