import { Flex, Icon, Text, Tooltip } from "@chakra-ui/react";
import { CiFileOn } from "react-icons/ci";
import { useSelector } from "react-redux";

const ClientCaseTile = () => {
	const transformNumber = (num) => {
		let res;
		if (num < 1000) {
			res = num.toFixed(2);
			if (res.slice(-2, res.length) === "00") {
				res = res.slice(0, -3);
			}
		} else if (num < 1000000) {
			res = (num / 1000).toFixed(2);
			if (res.slice(-2, res.length) === "00") {
				res = res.slice(0, -3);
			}
			res += "K";
		} else {
			res = (num / 1000000).toFixed(2);
			if (res.slice(-2, res.length) === "00") {
				res = res.slice(0, -3);
			}
			res += "M";
		}
		return res;
	};
	let totalCases = useSelector((state) => state.userCases.totalCases)["total"];

	return (
		<Tooltip label={totalCases.toString() + " cases filed"} bg='rgba(0, 0, 0, 0.7)' fontSize='1.5vw'>
			<Flex
				className='conclude-tile component-dashboard'
				w={{ base: "45%", md: "45%", xl: "45%" }}
				aspectRatio='1.2'
				bgColor='rgba(79, 209, 197, 0.7)'
				alignItems='center'
				justifyContent='center'
			>
				<Flex flexDir='column' alignItems='center' justifyContent='center'>
					{/* Icon */}
					<Icon className='nav-items-icon' as={CiFileOn} w='3vw' h='3vw' />
					{/* Description 1*/}
					<Text mt='2vw' fontSize='1.5vw' fontWeight='bold'>
						{transformNumber(totalCases)}
					</Text>
					{/* Description 2*/}
					<Text fontSize='1vw'>Cases Filed</Text>
				</Flex>
			</Flex>
		</Tooltip>
	);
};

export default ClientCaseTile;
