import { Flex } from "@chakra-ui/react";
// import Footer from "./Footer.jsx";
// import ClientInboundShipScanTile from "./ClientInboundShipScanTile.jsx";
// import ClientInboundShipWaitingTile from "./ClientInboundShipWaitingTile.jsx";
// import ClientInboundShipReimbTile from "./ClientInboundShipReimbTile.jsx";
// import ClientInboundShipNegoTile from "./ClientInboundShipNegoTile.jsx";
import InboundShipDetail from "./InboundShipDetail.jsx";

const ClientInboundShipment = () => {
	return (
		// The Main Wrapper
		<Flex w='85vw' paddingLeft='1vw' paddingRight='1vw' paddingTop='1vw' overflow='auto'>
			{/* Main Content, the following Flexbox makes the window scrollable, if setting flexdirection = column */}
			{/* at an flexbox. Then that flexbox's content is not scrollable anymore, since flexdirection forces the */}
			{/* its' content to fit the space. Therefore, we need the following wrapping flexbox. */}
			<Flex w='100%' flexDir='column' justifyContent='space-between'>
				{/* The First Row - Info Part */}
				<Flex
					w='100%'
					flexDir='column'
					alignItems='center'
					justifyContent='flex-start'
					borderRadius='1vw'
					marginBottom='3em'
				>
					{/* Row 1, Four Conclusion Tiles */}
					{/* <Flex
						w='100%'
						alignItems='center'
						justifyContent='space-around'
						borderRadius='1vw'
						flexDir={{ base: "column", md: "row", lg: "row" }}
						marginTop={{ base: "0.5em", md: "0.5em" }}
						marginBottom={{ base: "0.5em", md: "0.5em" }}
					>
						<Flex w={{ base: "100%", md: "50%", lg: "50%" }} alignItems='center' justifyContent='space-around'>
							<ClientInboundShipScanTile />
							<ClientInboundShipReimbTile />
						</Flex>

						<Flex
							w={{ base: "100%", md: "50%", lg: "50%" }}
							alignItems='center'
							justifyContent='space-around'
							marginTop={{ base: "0.5em", md: "0em", lg: "0em" }}
						>
							<ClientInboundShipNegoTile />
							<ClientInboundShipWaitingTile />
						</Flex>
					</Flex> */}

					<Flex
						w='100%'
						alignItems='center'
						justifyContent='flex-start'
						borderRadius='1vw'
						flexDir='column'
						marginBottom={{ base: "2em", md: "2em", lg: "2em" }}
					>
						<InboundShipDetail />
					</Flex>
				</Flex>
				{/* The Second Row - Footer */}
				{/* <Flex w='100%' borderRadius='1vw' flexDir='column' alignItems='center' justifyContent='space-between'>
					<Divider border='0.01rem solid rgba(0, 0, 0, 0.1)' margin={{ base: "1em", md: "2em" }} />
					<Footer />
				</Flex> */}
			</Flex>
		</Flex>
	);
};

export default ClientInboundShipment;
