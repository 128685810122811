import React from "react";
import {
	TableContainer,
	Table,
	Tbody,
	Th,
	Thead,
	Tr,
	useToast,
	Select,
	Flex,
	Wrap,
	Button,
	Icon,
} from "@chakra-ui/react";
import AdminClientCaseTableItem from "./AdminClientCaseTableItem.jsx";
import { useDispatch, useSelector } from "react-redux";
import { getPagiCases } from "../redux/actions/adminActions.js";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";

const AdminClientCase = ({ pagiCases }) => {
	const dispatch = useDispatch();
	const {
		loading,
		error,
		pagination: { totalPages, currentPage },
	} = useSelector((state) => state.adminClientCase);

	const paginationButtonClick = (curPage) => {
		dispatch(getPagiCases(curPage));
	};

	// Generate paginated buttons in accordance with users input.
	const genPagiBtns = () => {
		const allLeadBtns = () => {
			const buttons = [];
			for (let i = 1; i < currentPage; i++) {
				buttons.push(
					<Button key={i} onClick={() => paginationButtonClick(i)} size='sm'>
						{i}
					</Button>
				);
			}
			return buttons;
		};

		const allLastBtns = () => {
			const btns = [];
			for (let i = currentPage + 1; i <= totalPages; i++) {
				btns.push(
					<Button key={i} onClick={() => paginationButtonClick(i)} size='sm'>
						{i}
					</Button>
				);
			}
			return btns;
		};

		return (
			<>
				{currentPage - 3 > 1 ? (
					<>
						<Button key={1} onClick={() => paginationButtonClick(1)} size='sm'>
							1
						</Button>
						...
						<Button key={currentPage - 2} onClick={() => paginationButtonClick(currentPage - 2)} size='sm'>
							{currentPage - 2}
						</Button>
						<Button key={currentPage - 1} onClick={() => paginationButtonClick(currentPage - 1)} size='sm'>
							{currentPage - 1}
						</Button>
					</>
				) : (
					allLeadBtns()
				)}
				<Button colorScheme='teal' key={currentPage} onClick={() => paginationButtonClick(currentPage)} size='sm'>
					{currentPage}
				</Button>
				{currentPage + 3 < totalPages ? (
					<>
						<Button key={currentPage + 1} onClick={() => paginationButtonClick(currentPage + 1)} size='sm'>
							{currentPage + 1}
						</Button>
						<Button key={currentPage + 2} onClick={() => paginationButtonClick(currentPage + 2)} size='sm'>
							{currentPage + 2}
						</Button>
						...
						<Button key={totalPages} onClick={() => paginationButtonClick(totalPages)} size='sm'>
							{totalPages}
						</Button>
					</>
				) : (
					allLastBtns()
				)}
			</>
		);
	};

	return (
		<Flex flexDirection='column'>
			<TableContainer>
				<Table variant='simple'>
					<Thead>
						<Tr>
							<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>DATE FILED</Th>
							<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>CASE ID</Th>
							<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>STORE</Th>
							<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>
								<Select
									id='selectStatus'
									style={{ fontSize: "1vw", fontFamily: "Arial", fontWeight: "bold" }}
									variant='unstyled'
									placeholder='STATUS'
								>
									<option value='Negotiating'>STATUS (NEGOTIATING)</option>
									<option value='Reimbursed (Value TBD)'>STATUS (REIMBURSED-VALUE TBD)</option>
									<option value='Reimbursed'>STATUS (REIMBURSED)</option>
									<option value='Closed'>STATUS (CLOSED)</option>
								</Select>
							</Th>

							<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>REIMBURSEMENT ID</Th>
							<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>Quantity</Th>
							<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>Value</Th>
						</Tr>
					</Thead>
					<Tbody>
						{pagiCases.length > 0 &&
							pagiCases.map((singleCase) => <AdminClientCaseTableItem key={singleCase._id} singleCase={singleCase} />)}
					</Tbody>
				</Table>
			</TableContainer>
			{/* Pagination Buttons */}
			<Flex
				w='100%'
				alignItems='center'
				justifyContent='center'
				borderRadius='1vw'
				marginTop={{ base: "2em", md: "2em" }}
			>
				<Wrap spacing='1vw' justify='center' p='1vw'>
					<Button
						id='casePagiLeftArrow'
						onClick={() => paginationButtonClick(currentPage - 1 > 0 ? currentPage - 1 : 1)}
						size='sm'
					>
						<Icon className='nav-items-icon' as={MdKeyboardArrowLeft} boxSize={3} />
					</Button>
					{genPagiBtns()}
					<Button
						id='casePagiRightArrow'
						onClick={() => paginationButtonClick(currentPage + 1 < totalPages ? currentPage + 1 : totalPages)}
						size='sm'
					>
						<Icon className='nav-items-icon' as={MdKeyboardArrowRight} boxSize={3} />
					</Button>
				</Wrap>
			</Flex>
		</Flex>
	);
};

export default AdminClientCase;
