import { Td, Tr, Text, Link } from "@chakra-ui/react";

const CaseTableItem = ({ userCase }) => {
	return (
		<Tr>
			<Td>
				<Link href={userCase["caseLink"]} isExternal>
					<Text fontSize='0.9vw'>{userCase["caseID"]}</Text>
				</Link>
			</Td>
			<Td>
				<Text fontSize='0.9vw'>{userCase["reimbID"]}</Text>
			</Td>
			<Td>
				<Text fontSize='0.9vw'>{userCase["status"]}</Text>
			</Td>
			<Td>
				<Text fontSize='0.9vw'>{userCase["storeName"]}</Text>
			</Td>
			<Td>
				<Text fontSize='0.9vw'>{userCase["value"]}</Text>
			</Td>
			<Td>
				<Text fontSize='0.9vw'>{userCase["quantity"]}</Text>
			</Td>
			<Td>
				<Text fontSize='0.9vw'>{userCase["dateFiled"]}</Text>
			</Td>
		</Tr>
	);
};

export default CaseTableItem;
