import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Divider,
	Spinner,
	Stack,
	Wrap,
	Flex,
	Select,
	useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import AdminClientCase from "./AdminClientCase.jsx";
import { getClientCases } from "../redux/actions/adminActions.js";

const AdminCase = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const toast = useToast();

	const {
		loading,
		error,
		pagiCases,
		pagination: { totalPages, currentPage },
	} = useSelector((state) => state.adminClientCase);
	const { userInfo } = useSelector((state) => state.user);
	const [client, setClient] = useState("");

	// useEffect(() => {
	// 	if (!userInfo.isCaseAdmin) {
	// 		navigate("/portal");
	// 	}
	// 	if (error) {
	// 		toast({
	// 			description: `${error}`,
	// 			status: "error",
	// 			isClosable: true,
	// 		});
	// 	}
	// }, [navigate, userInfo, error, toast]);

	const searchCases = (selectedClient) => {
		setClient(selectedClient);
		if (selectedClient === "") {
			return;
		} else {
			dispatch(getClientCases(selectedClient));
		}
	};

	return (
		// The overall wrapper
		<Flex
			className='component-dashboard'
			w={{ base: "100%", md: "100%" }}
			marginTop={{ base: "1em", md: "1em", lg: "1em" }}
			borderRadius='1em'
			bgColor='white'
			flexDir='column'
		>
			{error && (
				<Alert status='error'>
					<AlertIcon />
					<AlertTitle>Oops!</AlertTitle>
					<AlertDescription>{error}</AlertDescription>
				</Alert>
			)}
			{loading ? (
				<Wrap justify='center'>
					<Stack direction='row' spacing='4'>
						<Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='teal.500' size='xl' />
					</Stack>
				</Wrap>
			) : (
				<Flex
					alignItems='center'
					justifyContent='flex-start'
					w='100%'
					flexDir='column'
					borderRadius='1vw'
					overflow='auto'
				>
					{/* Row 1 */}
					<Flex
						w='95%'
						h='5em'
						fontSize='1.2vw'
						fontWeight='bold'
						alignItems='center'
						borderRadius='1vw'
						justifyContent='space-between'
						paddingLeft='0.5em'
						paddingRight='0.5em'
					>
						<Flex w={{ base: "25%", md: "25%", lg: "25%" }}>
							<Select
								id='selectClient'
								style={{ fontSize: "1vw", fontFamily: "Arial", fontWeight: "bold" }}
								placeholder='Select Client'
								onChange={(e) => searchCases(e.target.value)}
							>
								{userInfo.clientEmails.length > 0 &&
									userInfo.clientEmails.map((clientEmail) => (
										<option key={clientEmail.clientEmail} value={clientEmail.clientEmail}>
											{clientEmail.clientEmail}
										</option>
									))}
							</Select>
						</Flex>
					</Flex>
					<Divider />
					{/* Row 2 */}
					<Flex alignItems='center' justifyContent='center' w='100%' overflow='auto'>
						{client === "" ? "" : <AdminClientCase pagiCases={pagiCases} />}
					</Flex>
				</Flex>
			)}
		</Flex>
	);
};

export default AdminCase;
