import axios from 'axios';
import {
    setToSaveCases,
    setToRemoveEvents,
    setAddedCaseID,
    setResetToSaveCases,
    setResetToRemoveEvents
} from '../slices/adminCase.js';
import {
    setEventError,
    setPagiEvents,
    setPagination,
    setEvents,
} from '../slices/event.js';
import {
    setClientCaseError,
    setClientCases,
    setClientIndexedCases,
    setClientPagiCases,
    setClientPagination,
    setClientWorkingCases
} from '../slices/adminClientCase.js'

export const createCase = (fnsku, storeName, caseType, status, caseID, quantity, value, reimbID) => async (dispatch, getState) => {
    let yearFiled = new Date().getFullYear().toString();
    let monthFiled = (new Date().getMonth() + 1).toString();
    let date = new Date().getDate().toString();

    monthFiled = (monthFiled.length === 1) ? `0${monthFiled}` : monthFiled;
    date = (date.length === 1) ? `0${date}` : date;
    const dateFiled = `${yearFiled}-${monthFiled}-${date}`;
    yearFiled = new Date().getFullYear();
    monthFiled = new Date().getMonth();
    const caseLink = `https://sellercentral.amazon.com/cu/case-dashboard/view-case?ref=sc_cd_lobby_vc_v3&ie=UTF&caseID=${caseID}`
    if (!(status === 'NA')) {
        dispatch(setToSaveCases({ fnsku, caseLink, caseID, caseType, status, 'quantity': Number(quantity), 'value': Number(value), dateFiled, monthFiled, yearFiled, storeName, reimbID }));
        dispatch(setAddedCaseID({ caseID, fnsku }));
    }
    dispatch(setToRemoveEvents(fnsku));
}

export const saveToDatabaseAction = (toSaveCases, toRemoveEvents, client, eventType) => async (dispatch, getState) => {
    const { pagination: { eventsPerPage } } = getState().event;
    try {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const addCaseResult = await axios.post('/api/admin/case-admin/add-case', { toSaveCases, client }, config);
        if (addCaseResult) {
            const { data } = (await axios.post('/api/admin/case-admin/event', { toRemoveEvents, client, eventType }, config));
            data.updatedEvents.forEach((event, index) => {
                event.idx = index
                event.isSaved = 0
                event.eventStatus = ""
                event.caseID = ""
                event.quantityReimb = ""
                event.valueReimb = ""
                event.reimbID = ""
            });
            dispatch(setEvents(data.updatedEvents));
            dispatch(setResetToSaveCases());
            dispatch(setResetToRemoveEvents());
            if (data.updatedEvents.length > 0) {
                const newTotalPages = Math.ceil(data.updatedEvents.length / eventsPerPage);
                const startIndex = 0
                const endIndex = startIndex + eventsPerPage;
                const paginatedEvents = data.updatedEvents.slice(startIndex, endIndex);
                dispatch(setPagination({ eventsPerPage, 'currentPage': 1, 'totalPages': newTotalPages }));
                dispatch(setPagiEvents(paginatedEvents));
            }

        }
    } catch (error) {
        dispatch(
            setEventError(
                error.response && error.response.data
                    ? error.response.data
                    : error.message
                        ? error.message
                        : 'An unexpected error has occured. Please try again later.'
            )
        );
    }
}

export const getClientCases = (client) => async (dispatch, getState) => {
    const { pagination: { casesPerPage, currentPage } } = getState().adminClientCase;
    try {
        const config = { headers: { 'Content-Type': 'application/json' }, params: { 'email': client } };
        const { data } = await axios.get(`/api/admin/case-admin/get-case`, config);
        const userCases = data.userCases;
        userCases.forEach((userCase, idx) => {
            userCase.index = idx
        });
        dispatch(setClientCases(userCases));
        if (userCases.length > 0) {
            const newTotalPages = Math.ceil(userCases.length / casesPerPage);
            const startIndex = (currentPage - 1) * casesPerPage;
            const endIndex = startIndex + casesPerPage;
            const paginatedCases = userCases.slice(startIndex, endIndex);
            dispatch(setClientPagination({ casesPerPage, currentPage, 'totalPages': newTotalPages }));
            dispatch(setClientPagiCases(paginatedCases));
            // createIndexedCases(data.userCases, data.userStore);
        }
    } catch (error) {
        dispatch(
            setClientCaseError(
                error.response && error.response.data
                    ? error.response.data
                    : error.message
                        ? error.message
                        : 'An unexpected error has occured. Please try again later.'
            )
        );
    }
}

export const getPagiCases = (clickedPage) => async (dispatch, getState) => {
    const curCases = getState().adminClientCase.workingCases;
    const { casesPerPage, totalPages } = getState().adminClientCase.pagination;
    const startIndex = (clickedPage - 1) * casesPerPage;
    const endIndex = startIndex + casesPerPage;
    const paginatedCases = curCases.slice(startIndex, endIndex);
    dispatch(setClientPagination({ casesPerPage, 'currentPage': clickedPage, totalPages }));
    dispatch(setClientPagiCases(paginatedCases));
}