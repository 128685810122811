import { TableContainer, Table, Thead, Tr, Th, Tbody, Select } from "@chakra-ui/react";

const AdminEventInboundNew = () => {
	return (
		<TableContainer whiteSpace='wrap'>
			<Table size='sm'>
				<Thead>
					<Tr>
						<Th>
							<Select
								id='createdDate'
								style={{ fontSize: "1vw", fontFamily: "Arial", fontWeight: "bold" }}
								variant='unstyled'
								placeholder='CREATED DATE'
								// onChange={(e) => dateOrderChanged(e.target.value)}
							>
								<option value='asc'>CREATED DATE (ASC)</option>
								<option value='des'>CREATED DATE (DES)</option>
							</Select>
						</Th>
						<Th>
							<Select
								id='store'
								style={{ fontSize: "1vw", fontFamily: "Arial", fontWeight: "bold" }}
								variant='unstyled'
								placeholder='STORE'
								// onChange={(e) => dateOrderChanged(e.target.value)}
							>
								<option value='asc'>CREATED DATE (ASC)</option>
								<option value='des'>CREATED DATE (DES)</option>
							</Select>
						</Th>
						<Th isNumeric>
							<Select
								id='discrepancy'
								style={{ fontSize: "1vw", fontFamily: "Arial", fontWeight: "bold" }}
								variant='unstyled'
								placeholder='DISCREPANCIES'
								// onChange={(e) => disOrderChanged(e.target.value)}
							>
								<option value='asc'>DISCREPANCIES (MIN TO MAX)</option>
								<option value='des'>DISCREPANCIES (MAX TO MIN)</option>
							</Select>
						</Th>
						<Th>SHIPMENT ID</Th>
						<Th>MSKUs</Th>
						<Th>CASE ID</Th>
						<Th>DOC REQUIRED</Th>
					</Tr>
				</Thead>
				<Tbody>
					{/* {shipment.items.length > 0 &&
                shipment.items.map((item) => <ShipTableItemDetail key={item._id} item={item} />)} */}
				</Tbody>
			</Table>
		</TableContainer>
	);
};

export default AdminEventInboundNew;
