import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Divider,
	Spinner,
	Stack,
	Wrap,
	Flex,
	Select,
	useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import { getEvent } from "../redux/actions/eventActions";
import AdminEventLost from "./AdminEventLost";
import AdminEventInbound from "./AdminEventInbound";

const AdminEvent = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const toast = useToast();
	const { loading, error } = useSelector((state) => state.event);

	const { userInfo } = useSelector((state) => state.user);
	const [client, setClient] = useState("");
	const [eventType, setEventType] = useState("");

	useEffect(() => {
		if (!userInfo.isCaseAdmin) {
			navigate("/portal");
		}
		if (error) {
			toast({
				description: `${error}`,
				status: "error",
				isClosable: true,
			});
		}
	}, [navigate, userInfo, error, toast]);

	const clientChanged = (curClient) => {
		setClient(curClient);
		if (curClient === "" || eventType === "" || eventType === "inbound") return;
		dispatch(getEvent(curClient, eventType));
	};

	const eventTypeChanged = (curEventType) => {
		setEventType(curEventType);
		if (curEventType === "" || curEventType === "inbound" || client === "") return;
		dispatch(getEvent(client, curEventType));
	};

	return (
		// The overall wrapper
		<Flex
			className='component-dashboard'
			w={{ base: "100%", md: "100%" }}
			marginTop={{ base: "1em", md: "1em", lg: "1em" }}
			borderRadius='1em'
			bgColor='white'
			flexDir='column'
		>
			{error && (
				<Alert status='error'>
					<AlertIcon />
					<AlertTitle>Oops!</AlertTitle>
					<AlertDescription>{error}</AlertDescription>
				</Alert>
			)}
			{loading ? (
				<Wrap justify='center'>
					<Stack direction='row' spacing='4'>
						<Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='teal.500' size='xl' />
					</Stack>
				</Wrap>
			) : (
				<Flex
					alignItems='center'
					justifyContent='flex-start'
					w='100%'
					flexDir='column'
					borderRadius='1vw'
					overflow='auto'
				>
					{/* Row 1 */}
					<Flex
						w='95%'
						h='5em'
						fontSize='1.2vw'
						fontWeight='bold'
						alignItems='center'
						borderRadius='1vw'
						justifyContent='space-between'
						paddingLeft='0.5em'
						paddingRight='0.5em'
					>
						<Flex w={{ base: "25%", md: "25%", lg: "25%" }}>
							<Select
								id='selectClient'
								style={{ fontSize: "1vw", fontFamily: "Arial", fontWeight: "bold" }}
								placeholder='Select Client'
								onChange={(e) => clientChanged(e.target.value)}
							>
								{userInfo.clientEmails.length > 0 &&
									userInfo.clientEmails.map((clientEmail) => (
										<option key={clientEmail.clientEmail} value={clientEmail.clientEmail}>
											{clientEmail.clientEmail}
										</option>
									))}
							</Select>
						</Flex>
						<Flex w={{ base: "25%", md: "25%", lg: "25%" }}>
							<Select
								id='selectType'
								placeholder='Select Type'
								style={{ fontSize: "1vw", fontFamily: "Arial", fontWeight: "bold" }}
								onChange={(e) => {
									eventTypeChanged(e.target.value);
								}}
							>
								<option key='inbound' value='inbound'>
									Inbound Shipping
								</option>
								<option key='lost' value='lost'>
									Warehouse Lost
								</option>
								<option key='damaged' value='damaged'>
									Warehouse Damaged
								</option>
								<option key='customerReturn' value='customerReturn'>
									Customer Return
								</option>
								<option key='outbound' value='outbound'>
									Removal Shipping
								</option>
								<option key='other' value='other'>
									Others
								</option>
							</Select>
						</Flex>
					</Flex>
					<Divider />
					{/* Row 2 */}
					<Flex alignItems='center' justifyContent='center' w='100%' overflow='auto'>
						{eventType === "lost" ? (
							<AdminEventLost client={client} eventType={eventType} />
						) : eventType === "inbound" ? (
							<AdminEventInbound />
						) : (
							""
						)}
					</Flex>
				</Flex>
			)}
		</Flex>
	);
};

export default AdminEvent;
