import "../Styles/globals.css";
import "../Styles/landingScreen.css";
import { Flex, Heading, Button, List, ListItem, Text, Spacer, Icon, Divider, Link } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator } from "@chakra-ui/react";
import MainHeaderColor from "../components/MainHeaderColor";
import Footer from "../components/Footer";
import { BiSolidChevronDownSquare } from "react-icons/bi";

const HelpScreen = () => (
	// The viewport flexbox. bgColor='rgba(242, 242, 242, 0.7)'
	<div>
		{/* First part: Header. */}
		<Flex position='fixed' w='100vw' h={{ base: "3em", md: "3em", lg: "3em" }}>
			<MainHeaderColor />
		</Flex>
		{/* Second part: . */}
		<Flex
			paddingTop={{ base: "6em", md: "6em", lg: "6em" }}
			paddingLeft={{ base: "2em", md: "2em", lg: "2em" }}
			paddingRight={{ base: "2em", md: "2em", lg: "2em" }}
			marginBottom={{ base: "2em", md: "2em", lg: "2em" }}
			w='100vw'
			minH={{ base: "30em", md: "30em", lg: "30em" }}
		>
			<Tabs position='relative' variant='unstyled'>
				<TabList>
					<Tab>Private Developer</Tab>
					<Tab>Public Developer</Tab>
				</TabList>
				<TabIndicator mt='-1.5px' height='2px' bg='teal' borderRadius='1px' />
				<TabPanels>
					{/* Private Developer Instructions */}
					<TabPanel>
						<Flex w='100%' flexDir='column' justifyContent='center'>
							<Text fontSize={{ base: "1em", md: "1em", lg: "1em" }}>
								We are privileged to be your business's private developer. This article give you the guidance of adding
								TinkerBoost as your business's private developer.
							</Text>

							<Divider
								marginTop={{ base: "1em", md: "1em", lg: "1em" }}
								marginBottom={{ base: "1em", md: "1em", lg: "1em" }}
							/>

							<Flex w='100%'>
								<Tabs w='100%' position='relative' variant='unstyled'>
									<TabList>
										<Tab>Step 1. Create a developer profile</Tab>
										<Tab id='privateStep2'>Step 2. Create an app client</Tab>
										<Tab>Step 3. Create a new user</Tab>
									</TabList>
									<TabIndicator mt='-1.5px' height='2px' bg='teal' borderRadius='1px' />
									<TabPanels>
										{/* Step 1 */}
										<TabPanel>
											<Flex w='100%' flexDir='column'>
												<Text>
													1. Go to{" "}
													<Link
														href='https://sellercentral.amazon.com/sellingpartner/developerconsole/'
														isExternal
														fontWeight='bold'
													>
														Amazon Seller Central Developer Console <ExternalLinkIcon mx='2px' />
													</Link>
													. You might need to log in first if you haven't.
												</Text>

												<Text>
													2. If you have completed a developer profile for your selling account, you have completed the
													first step, you can now go to{" "}
													<Text as='span' fontWeight='bold'>
														Step 2
													</Text>
													.
												</Text>

												<Text>
													3. Otherwise, choose{" "}
													<Text as='span' fontWeight='bold'>
														Proceed to Developer Profile
													</Text>
													.
												</Text>

												<Text>
													4. Enter your business's information. You don't need to enter your website to submit this
													form.
												</Text>

												<Text>
													5. From the{" "}
													<Text as='span' fontWeight='bold'>
														Data Access
													</Text>{" "}
													dropdown, choose{" "}
													<Text as='span' fontWeight='bold'>
														Private Developer: I build application(s) that integrate my own company with Amazon Services
														APIs
													</Text>
													.
												</Text>

												<Text>
													6. For{" "}
													<Text as='span' fontWeight='bold'>
														Roles
													</Text>
													, check{" "}
													<Text as='span' fontWeight='bold'>
														Amazon Fulfilment
													</Text>
													, and{" "}
													<Text as='span' fontWeight='bold'>
														Inventory and Order Management
													</Text>
													. These two options authorize TinkerBoost to audit the shipment and inventory for you.
												</Text>

												<Text>
													7. For the first input box of{" "}
													<Text as='span' fontWeight='bold'>
														Use Cases
													</Text>
													, enter{" "}
													<Text as='span' fontWeight='bold'>
														The app automate the shipment and inventory auditing process by automatically analizing data
														and reporting issues
													</Text>
													.
												</Text>

												<Text>
													8. For the second input box of{" "}
													<Text as='span' fontWeight='bold'>
														Use Cases
													</Text>
													, enter{" "}
													<Text as='span' fontWeight='bold'>
														The app increases a FBA sellers' cash flow and helps business grow
													</Text>
													.
												</Text>

												<Text>
													9. Choose{" "}
													<Text as='span' fontWeight='bold'>
														Yes{" "}
													</Text>
													for all options under the section{" "}
													<Text as='span' fontWeight='bold'>
														Security Controls
													</Text>
													. TinkerBoost implemented all security features to protect your data.
												</Text>

												<Text>
													10. For last two input boxes, both enter{" "}
													<Text as='span' fontWeight='bold'>
														N/A
													</Text>
													. TinkerBoost does not share your information with anyone.
												</Text>

												<Text>
													11. Check the agreement box, and click the button{" "}
													<Text as='span' fontWeight='bold'>
														Register
													</Text>
													.
												</Text>

												<Text>12. Your application shall be approved in a few minutes.</Text>
											</Flex>
										</TabPanel>

										{/* Step 2 */}
										<TabPanel>
											<Flex w='100%' flexDir='column'>
												<Text>
													1. Go to{" "}
													<Link
														href='https://sellercentral.amazon.com/sellingpartner/developerconsole/'
														isExternal
														fontWeight='bold'
													>
														Amazon Seller Central Developer Console <ExternalLinkIcon mx='2px' />
													</Link>
													. You might need to log in first if you haven't.
												</Text>

												<Text>
													2. Click the button
													<Button
														size={{ base: "1em", md: "1em", lg: "1em" }}
														bgColor='rgba(0,0,0,0.7)'
														color='white'
														marginLeft='0.5em'
														fontSize={{ base: "0.8em", md: "0.8em", lg: "0.8em" }}
														borderRadius='0'
														padding='0.4em'
													>
														+ Add new app client
													</Button>
												</Text>

												<Text>
													3. Choose any name you like for{" "}
													<Text as='span' fontWeight='bold'>
														App name
													</Text>
													. For{" "}
													<Text as='span' fontWeight='bold'>
														API Type
													</Text>
													, choose{" "}
													<Text as='span' fontWeight='bold'>
														SP API
													</Text>
													.
												</Text>

												<Text>
													4. For{" "}
													<Text as='span' fontWeight='bold'>
														Roles
													</Text>
													, choose{" "}
													<Text as='span' fontWeight='bold'>
														Inventory and Order Management
													</Text>
													, and{" "}
													<Text as='span' fontWeight='bold'>
														Amazon Fulfilment
													</Text>
													.
												</Text>

												<Text>
													5. For{" "}
													<Text as='span' fontWeight='bold'>
														Restricted Data Token
													</Text>{" "}
													question , choose{" "}
													<Text as='span' fontWeight='bold'>
														No, I will not delegate access to PII to another developer’s application
													</Text>
													, and click
													<Button
														size={{ base: "1em", md: "1em", lg: "1em" }}
														bgColor='teal'
														color='white'
														marginLeft='0.5em'
														fontSize={{ base: "0.8em", md: "0.8em", lg: "0.8em" }}
														borderRadius='0'
														padding='0.4em'
													>
														Save and exit
													</Button>
												</Text>

												<Text>
													6. Now you shall see your app's information below the button{" "}
													<Button
														size={{ base: "1em", md: "1em", lg: "1em" }}
														bgColor='rgba(0,0,0,0.7)'
														color='white'
														marginLeft='0.5em'
														fontSize={{ base: "0.8em", md: "0.8em", lg: "0.8em" }}
														borderRadius='0'
														padding='0.4em'
													>
														+ Add new app client
													</Button>
													. Click{" "}
													<Text as='span' fontWeight='bold'>
														View
													</Text>{" "}
													under{" "}
													<Text as='span' fontWeight='bold'>
														LWA credentials
													</Text>
													. You can see your app's{" "}
													<Text as='span' fontWeight='bold'>
														Client identifier
													</Text>{" "}
													and{" "}
													<Text as='span' fontWeight='bold'>
														Client secret
													</Text>{" "}
													in the popup window. Copy and save them.
												</Text>

												<Text>
													7. Click the <Icon as={BiSolidChevronDownSquare} /> button next to the{" "}
													<Button
														size={{ base: "1em", md: "1em", lg: "1em" }}
														bgColor='rgba(0,0,0,0.7)'
														color='white'
														marginLeft='0.5em'
														fontSize={{ base: "0.8em", md: "0.8em", lg: "0.8em" }}
														borderRadius='0'
														padding='0.4em'
													>
														Edit App
													</Button>{" "}
													button. Click{" "}
													<Text as='span' fontWeight='bold'>
														Authorize
													</Text>
													, and you will be redirected to the{" "}
													<Text as='span' fontWeight='bold'>
														Manage Authorizations
													</Text>{" "}
													page.
												</Text>

												<Text>
													8. On the new page, click the button{" "}
													<Button
														size={{ base: "1em", md: "1em", lg: "1em" }}
														bgColor='rgba(0,0,0,0.7)'
														color='white'
														marginLeft='0.5em'
														fontSize={{ base: "0.8em", md: "0.8em", lg: "0.8em" }}
														borderRadius='0'
														padding='0.4em'
													>
														Authorize app
													</Button>{" "}
													. Now you shall get a{" "}
													<Text as='span' fontWeight='bold'>
														Refresh Token
													</Text>
													. Copy and save it.
												</Text>
											</Flex>
										</TabPanel>

										{/* Step 3 */}
										<TabPanel>
											<Flex w='100%' flexDir='column'>
												<Text>
													1. Go to{" "}
													<Link
														href='https://sellercentral.amazon.com/gp/account-manager/home.html/'
														isExternal
														fontWeight='bold'
													>
														User Permissions <ExternalLinkIcon mx='2px' />
													</Link>
													page. You might need to log in first if you haven't.
												</Text>

												<Text>
													2. Under{" "}
													<Text as='span' fontWeight='bold'>
														Add a New User
													</Text>{" "}
													section, enter{" "}
													<Text as='span' fontWeight='bold'>
														case@tinkerboost.com
													</Text>{" "}
													under{" "}
													<Text as='span' fontWeight='bold'>
														E-mail Address
													</Text>
													. Click the button{" "}
													<Text as='span' fontWeight='bold'>
														Invite
													</Text>{" "}
													to invite TinkerBoost to be your new user and file reimbursement cases for you.
												</Text>

												<Text>
													3. After we accept your invitation, you shall see{" "}
													<Text as='span' fontWeight='bold'>
														case@tinkerboost.com
													</Text>{" "}
													in your{" "}
													<Text as='span' fontWeight='bold'>
														Current Users
													</Text>{" "}
													section. Click{" "}
													<Text as='span' fontWeight='bold'>
														Manage Permissions
													</Text>{" "}
													button to authorize TinkerBoost permissions.
												</Text>

												<Text>
													4. On the newly opened page, under the section{" "}
													<Text as='span' fontWeight='bold'>
														Inventory
													</Text>
													, locate the option{" "}
													<Text as='span' fontWeight='bold'>
														Manage FBA Inventory/Shipments
													</Text>
													. Choose{" "}
													<Text as='span' fontWeight='bold'>
														View and edit
													</Text>{" "}
													to give TinkerBoost the authorization to audit your inbound shipments.
												</Text>

												<Text>
													5. Under the section{" "}
													<Text as='span' fontWeight='bold'>
														Settings
													</Text>
													, locate the option{" "}
													<Text as='span' fontWeight='bold'>
														Manage your cases
													</Text>
													. Choose{" "}
													<Text as='span' fontWeight='bold'>
														View and edit
													</Text>{" "}
													to give TinkerBoost the authorization to file reimbursement cases for you.
												</Text>

												<Text>
													6. Scroll down to the bottom of the page, click the button{" "}
													<Text as='span' fontWeight='bold'>
														Continue
													</Text>{" "}
													to save the changes.
												</Text>

												<Text>
													7. Sign up at{" "}
													<Text as='span' fontWeight='bold'>
														tinkerboost.com
													</Text>
													, and enter your{" "}
													<Text as='span' fontWeight='bold'>
														Refresh token
													</Text>
													,{" "}
													<Text as='span' fontWeight='bold'>
														Client identifier
													</Text>
													, and{" "}
													<Text as='span' fontWeight='bold'>
														Client secret
													</Text>{" "}
													when adding your store. Once your store is added, you are done with the setup. We will begin
													audit your account. You shall see updates in your account in 1 business day.
												</Text>
											</Flex>
										</TabPanel>
									</TabPanels>
								</Tabs>
							</Flex>
						</Flex>
					</TabPanel>

					<TabPanel>
						<Text>
							{" "}
							Coming soon! TinkerBoost is looking forward to serve you as a public developer in the near future.{" "}
						</Text>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Flex>

		{/* Third part: Footer. */}
		<Flex w='100vw' h='10em' overflow='auto' alignItems='center' justifyContent='center'>
			<Footer />
		</Flex>
	</div>
);

export default HelpScreen;
