import { TableContainer, Table, Thead, Tr, Th, Tbody } from "@chakra-ui/react";

const AdminEventInboundDocRequired = () => {
	return (
		<TableContainer whiteSpace='wrap'>
			<Table size='sm'>
				<Thead>
					<Tr>
						<Th>MSKU</Th>
						<Th>Title</Th>
						<Th>ASIN</Th>
						<Th>FNSKU</Th>
						<Th isNumeric>EXPECTED</Th>
						<Th isNumeric>RECEIVED</Th>
						<Th isNumeric>DISCREPANCY</Th>
					</Tr>
				</Thead>
				<Tbody>
					{/* {shipment.items.length > 0 &&
        shipment.items.map((item) => <ShipTableItemDetail key={item._id} item={item} />)} */}
				</Tbody>
			</Table>
		</TableContainer>
	);
};

export default AdminEventInboundDocRequired;
