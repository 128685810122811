import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    loading: false,
    error: null,
    events: [],
    indexedEvents: {},
    workingEvents: [],
    pagiEvents: [],
    pagination: {
        'eventsPerPage': 10,
        'currentPage': 1,
        'totalPages': 0
    },
};

export const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        setEventError: (state, { payload }) => {
            state.error = payload;
        },
        setEvents: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.events = payload;
        },
        setPagination: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.pagination = payload;
        },
        setPagiEvents: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.pagiEvents = payload;
        },
        setEventIsSaved: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.events[payload].isSaved = 1;
        },
        setEventIsUnsaved: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.events[payload].isSaved = 0;
        },
        setEventStatus: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.events[payload.idx].eventStatus = payload.eventStatus;
        },
        setCaseID: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.events[payload.idx].caseID = payload.caseID;
        },
        setQuantity: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.events[payload.idx].quantityReimb = payload.quantityReimb;
        },
        setValue: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.events[payload.idx].valueReimb = payload.valueReimb;
        },
        setReimbID: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.events[payload.idx].reimbID = payload.reimbID;
        },
    },
});

// The followings are actually generated action creators, not actions themselves.
// Action creators and action types are generated for each case reducer function.
export const {
    setEventError,
    setPagiEvents,
    setPagination,
    setEvents,
    setEventIsSaved,
    setEventIsUnsaved,
    setEventStatus,
    setCaseID,
    setQuantity,
    setReimbID,
    setValue
} = eventSlice.actions;

export default eventSlice.reducer;

export const eventSelector = (state) => state.event;