// case is a reserved word in javaScript.
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    loading: false,
    error: null,
    userCases: [],
    totalCases: { 'total': 0, 'detail': { 'inbound': 0, 'lost': 0, 'damaged': 0, 'outbound': 0, 'customerReturn': 0, 'other': 0 } },
    totalReimbursedValue: { 'total': 0, 'detail': { 'inbound': 0, 'lost': 0, 'damaged': 0, 'outbound': 0, 'customerReturn': 0, 'other': 0 } },
    totalReimbursedQuantity: { 'total': 0, 'detail': { 'inbound': 0, 'lost': 0, 'damaged': 0, 'outbound': 0, 'customerReturn': 0, 'other': 0 } },
    pagiCases: [],
    pagination: {
        'casesPerPage': 10,
        'currentPage': 1,
        'totalPages': 0
    },
    curStatus: '',
    curStore: '',
    curClaimValueOrder: null,
    curDateFiledOrder: null,
    curQuantityOrder: null,
    indexedCases: {},
    workingCases: null,
    barChartReimbData: {
        labels: [],
        datasets: [],
    },
    barChartCaseData: {
        labels: [],
        datasets: [],
    },
    doughnutChartReimbData: {
        labels: [],
        datasets: [],
    },
    doughnutChartTypeData: {
        labels: [],
        datasets: [],
    },
};

export const userCasesSlice = createSlice({
    name: 'userCase',
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
        },
        setUserCases: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.userCases = payload;
            state.workingCases = payload;
        },
        setPagiCases: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.pagiCases = payload;
        },
        setWorkingCases: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.workingCases = payload;
        },
        setError: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
        resetError: (state) => {
            state.error = null;
        },
        setPagination: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.pagination = payload;
        },
        setCurStatus: (state, { payload }) => {
            state.curStatus = payload;
        },
        setCurStore: (state, { payload }) => {
            state.curStore = payload;
        },
        setCurClaimValueOrder: (state, { payload }) => {
            state.curClaimValueOrder = payload;
            state.curQuantityOrder = null;
            state.curDateFiledOrder = null;
        },
        setCurDateFiledOrder: (state, { payload }) => {
            state.curDateFiledOrder = payload;
            state.curClaimValueOrder = null;
            state.curQuantityOrder = null;
        },
        setCurQuantityOrder: (state, { payload }) => {
            state.curQuantityOrder = payload;
            state.curDateFiledOrder = null;
            state.curClaimValueOrder = null;
        },
        setIndexedCases: (state, { payload }) => {
            state.indexedCases = payload;
        },
        setBarChartReimbData: (state, { payload }) => {
            state.barChartReimbData.labels = payload.barChartLabels;
            state.barChartReimbData.datasets = payload.barReimbData;
        },
        setBarChartCaseData: (state, { payload }) => {
            state.barChartCaseData.labels = payload.barChartLabels;
            state.barChartCaseData.datasets = payload.barCaseData;
        },
        setDoughnutChartReimbData: (state, { payload }) => {
            state.doughnutChartReimbData = payload;
        },
        setDoughnutChartTypeData: (state, { payload }) => {
            state.doughnutChartTypeData = payload;
        },
        setTotalCases: (state, { payload }) => {
            state.totalCases = payload;
        },
        setTotalReimbursedValue: (state, { payload }) => {
            state.totalReimbursedValue = payload;
        },
        setTotalReimbursedQuantity: (state, { payload }) => {
            state.totalReimbursedQuantity = payload;
        },
    },
});

// The followings are actually generated action creators, not actions themselves.
// Action creators and action types are generated for each case reducer function.
export const {
    setLoading,
    setUserCases,
    setPagiCases,
    setError,
    setPagination,
    resetError,
    setCurStatus,
    setCurStore,
    setCurClaimValueOrder,
    setCurDateFiledOrder,
    setCurQuantityOrder,
    setIndexedCases,
    setWorkingCases,
    setBarChartReimbData,
    setBarChartCaseData,
    setDoughnutChartReimbData,
    setDoughnutChartTypeData,
    setTotalCases,
    setTotalReimbursedValue,
    setTotalReimbursedQuantity
} = userCasesSlice.actions;

export default userCasesSlice.reducer;

export const userCasesSelector = (state) => state.userCases;