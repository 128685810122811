import { useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SetupForm from "./SetupForm";
import { useSelector, useDispatch } from "react-redux";
import {
	Flex,
	Spinner,
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Heading,
	Divider,
	Icon,
} from "@chakra-ui/react";
import PaymentMethods from "./PaymentMethods";
import { getFinancialInfo } from "../redux/actions/checkoutActions";
import EmptyNotice from "./EmptyNotice";
import { setFinantialInfoLoading } from "../redux/slices/user";
import { useSearchParams } from "react-router-dom";
import SavingCardResult from "./SavingCardResult";
import { IoCloseOutline } from "react-icons/io5";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
	"pk_live_51QA2bvApJEhg3lHoM63MS1gKCAXw8HIBNP1h4EaHCepNziiOXGTk8vJMEi84bIySrZ76lMyUZha3kSlwEnmUpBtM0056QlqM3o"
);

const ClientPayment = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [searchParams, setSearchParams] = useSearchParams();
	const {
		userInfo,
		stripeIntentSecret,
		stripeCustomerSessionClientSecret,
		stripeCreditCardInfo,
		stripeBankDebitInfo,
		finantialInfoLoading,
	} = useSelector((state) => state.user);

	const dispatch = useDispatch();

	const options = {
		// passing the SetupIntent's client secret
		clientSecret: stripeIntentSecret,
		customerSessionclientSecret: stripeCustomerSessionClientSecret,
		// Fully customizable with appearance API.
		appearance: {
			theme: "stripe",
			variables: {
				colorPrimary: "#bfbfbf",
				colorBackground: "#ffffff",
				colorText: "#30313d",
				colorDanger: "#df1b41",
				fontFamily: "system-ui, sans-serif, Ideal Sans",
				spacingUnit: "0.5rem",
				borderRadius: "4px",
				fontSizeBase: "1rem",
				// See all possible variables below
			},
			layout: {
				type: "accordion",
				defaultCollapsed: true,
				radios: true,
				spacedAccordionItems: false,
			},
		},
	};

	useEffect(() => {
		dispatch(setFinantialInfoLoading(true));
		dispatch(getFinancialInfo(userInfo.name, userInfo.email, userInfo.stripeCustomerId));
	}, [dispatch, userInfo.name, userInfo.email, userInfo.stripeCustomerId]);

	return (
		<Flex w='85vw' overflow='auto' flexDir='column'>
			{/* Row 1 */}
			<Flex w='100%' h='20%' borderRadius='1vw' alignItems='flex-end' justifyContent='flex-end' padding='2rem'>
				<Button
					onClick={onOpen}
					variant='outline'
					h='10vh'
					fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
				>
					Add Payment Method
				</Button>
			</Flex>
			{/* Row 2 */}
			<Flex w='100%' h='80%' alignItems='center' justifyContent='center' overflow='auto' marginBottom='4em'>
				<Flex w='100%' alignItems='center' justifyContent='center'>
					{finantialInfoLoading ? (
						<Spinner />
					) : stripeCreditCardInfo || stripeBankDebitInfo ? (
						<PaymentMethods />
					) : searchParams.get("setup_intent_client_secret") ? (
						<SavingCardResult />
					) : (
						<EmptyNotice />
					)}
				</Flex>

				{/* <Flex w={{ base: "100%", md: "50%" }} justifyContent='center' alignItems='center'> */}
				{/* <Flex w={{ base: "100%", md: "50%" }}>
					{finantialInfoLoading ? (
						<Spinner />
					) : (
						// <Flex w={{ lg: "90%" }} flexDir='column'>
						// <Flex w='95%' flexDir='column' justifyContent='center' alignItems='center'>
						<Flex w='95%' flexDir='column'>
							<Elements stripe={stripePromise} options={options}>
								<SetupForm />
							</Elements>
						</Flex>
					)}
				</Flex> */}
			</Flex>
			<Modal isOpen={isOpen} onClose={onClose} size={{ lg: "full" }}>
				<ModalOverlay />
				<ModalContent>
					<Flex justifyContent='space-between'>
						<ModalHeader></ModalHeader>
						<Button mr={3} onClick={onClose} w='10%' h='10vh' bgColor='rgb(0,0 ,0, 0)'>
							<Icon className='nav-items-icon' as={IoCloseOutline} />
						</Button>
					</Flex>

					<ModalBody>
						<Flex w='100%'>
							{finantialInfoLoading ? (
								<Spinner />
							) : (
								<Flex w='100%' flexDir='column'>
									<Elements stripe={stripePromise} options={options}>
										<SetupForm />
									</Elements>
								</Flex>
							)}
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Flex>
	);
};

export default ClientPayment;
