import { Flex, Text } from "@chakra-ui/react";

const EmptyNotice = () => {
	return (
		<Flex w='100%' h='100%' flexDir='column' justifyContent='center' alignItems='center'>
			<Text fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}>
				You have no saved payment method
			</Text>
		</Flex>
	);
};

export default EmptyNotice;
