import { Flex } from "@chakra-ui/react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, scales } from "chart.js";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChartReimb = () => {
	const options = {
		responsive: true,
		plugins: {
			title: {
				display: false,
				text: "Reimbursed Value (Monthly)",
				position: "bottom",
			},
			legend: {
				position: "bottom",
				align: "center",
			},
		},
		// scales: {
		// 	x: {
		// 		grid: { drawOnChartArea: false },
		// 	},
		// 	y: {
		// 		grid: { drawOnChartArea: false },
		// 	},
		// },
	};
	const { barChartReimbData } = useSelector((state) => state.userCases);

	return (
		<Flex
			className='conclude-tile component-dashboard'
			w={{ base: "96%", md: "96%", lg: "96%" }}
			alignItems='center'
			justifyContent='space-around'
			flexDir='column'
			bgColor='white'
			aspectRatio='3/2'
		>
			<Flex className='conclude-tile' w='100%' alignItems='center' padding='0.5vw' fontSize='1vw' fontWeight='bold'>
				Reimbursed Value (Month)
			</Flex>
			<Bar options={options} data={barChartReimbData} />
		</Flex>
	);
};

export default BarChartReimb;
