import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Button,
	Divider,
	Spinner,
	Stack,
	TableContainer,
	Table,
	Tbody,
	Th,
	Thead,
	Tr,
	Text,
	Wrap,
	Flex,
	Select,
	Icon,
} from "@chakra-ui/react";
import {
	setCurStatus,
	setCurStore,
	setCurClaimValueOrder,
	setCurDateFiledOrder,
	setCurQuantityOrder,
} from "../redux/slices/userCase.js";
import { useDispatch, useSelector } from "react-redux";
import { getCustomizedCases } from "../redux/actions/caseActions";
import { getPagiCases } from "../redux/actions/caseActions";
import CaseTableItem from "./CaseTableItem";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";

const CaseDetails = () => {
	const dispatch = useDispatch();
	const {
		loading,
		error,
		pagiCases,
		pagination: { casesPerPage, currentPage, totalPages },
	} = useSelector((state) => state.userCases);

	const { store } = useSelector((state) => state.user.userInfo);

	const paginationButtonClick = (curPage) => {
		dispatch(getPagiCases(curPage));
	};

	const statusChanged = () => {
		const curStatus = document.getElementById("selectStatus").value;
		dispatch(setCurStatus(curStatus));
		dispatch(getCustomizedCases(true));
	};

	const storeChanged = () => {
		const curStore = document.getElementById("selectStore").value;
		dispatch(setCurStore(curStore));
		dispatch(getCustomizedCases(true));
	};

	const claimValueOrderChanged = () => {
		const curOrder = document.getElementById("selectValueOrder").value;
		document.getElementById("selectDateFiledOrder").value = "";
		document.getElementById("selectQuantityOrder").value = "";
		dispatch(setCurClaimValueOrder(curOrder));
		dispatch(getCustomizedCases(false));
	};

	const quantityOrderChanged = () => {
		const curOrder = document.getElementById("selectQuantityOrder").value;
		document.getElementById("selectDateFiledOrder").value = "";
		document.getElementById("selectValueOrder").value = "";
		dispatch(setCurQuantityOrder(curOrder));
		dispatch(getCustomizedCases(false));
	};

	const dateFiledOrderChanged = () => {
		const curOrder = document.getElementById("selectDateFiledOrder").value;
		document.getElementById("selectValueOrder").value = "";
		document.getElementById("selectQuantityOrder").value = "";
		dispatch(setCurDateFiledOrder(curOrder));
		dispatch(getCustomizedCases(false));
	};

	// Generate paginated buttons in accordance with users input.
	const genPagiBtns = () => {
		const allLeadBtns = () => {
			const buttons = [];
			for (let i = 1; i < currentPage; i++) {
				buttons.push(
					<Button key={i} onClick={() => paginationButtonClick(i)} size={{ base: "xs", md: "sm" }}>
						{i}
					</Button>
				);
			}
			return buttons;
		};

		const allLastBtns = () => {
			const btns = [];
			for (let i = currentPage + 1; i <= totalPages; i++) {
				btns.push(
					<Button key={i} onClick={() => paginationButtonClick(i)} size={{ base: "xs", md: "sm" }}>
						{i}
					</Button>
				);
			}
			return btns;
		};

		return (
			<>
				{currentPage - 3 > 1 ? (
					<>
						<Button key={1} onClick={() => paginationButtonClick(1)} size={{ base: "xs", md: "sm" }}>
							1
						</Button>
						...
						<Button
							key={currentPage - 2}
							onClick={() => paginationButtonClick(currentPage - 2)}
							size={{ base: "xs", md: "sm" }}
						>
							{currentPage - 2}
						</Button>
						<Button
							key={currentPage - 1}
							onClick={() => paginationButtonClick(currentPage - 1)}
							size={{ base: "xs", md: "sm" }}
						>
							{currentPage - 1}
						</Button>
					</>
				) : (
					allLeadBtns()
				)}
				<Button
					colorScheme='teal'
					key={currentPage}
					onClick={() => paginationButtonClick(currentPage)}
					size={{ base: "xs", md: "sm" }}
				>
					{currentPage}
				</Button>
				{currentPage + 3 < totalPages ? (
					<>
						<Button
							key={currentPage + 1}
							onClick={() => paginationButtonClick(currentPage + 1)}
							size={{ base: "xs", md: "sm" }}
						>
							{currentPage + 1}
						</Button>
						<Button
							key={currentPage + 2}
							onClick={() => paginationButtonClick(currentPage + 2)}
							size={{ base: "xs", md: "sm" }}
						>
							{currentPage + 2}
						</Button>
						...
						<Button key={totalPages} onClick={() => paginationButtonClick(totalPages)} size={{ base: "xs", md: "sm" }}>
							{totalPages}
						</Button>
					</>
				) : (
					allLastBtns()
				)}
			</>
		);
	};

	return (
		// The overall wrapper
		<Flex
			className='component-dashboard'
			alignItems='center'
			justifyContent='flex-start'
			w={{ base: "96%", md: "97%" }}
			marginTop='1.5em'
			flexDir='column'
			borderRadius='1vw'
			bgColor='white'
		>
			{error && (
				<Alert status='error'>
					<AlertIcon />
					<AlertTitle>Oops!</AlertTitle>
					<AlertDescription>{error}</AlertDescription>
				</Alert>
			)}
			{loading ? (
				<Wrap justify='center'>
					<Stack direction='row' spacing='4'>
						<Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='teal.500' size='xl' />
					</Stack>
				</Wrap>
			) : (
				<Flex alignItems='center' justifyContent='flex-start' w='100%' minH='50em' flexDir='column' borderRadius='1vw'>
					{/* Row 1 */}
					<Flex
						// bgColor='white'
						w='100%'
						h='5em'
						fontSize='1.2vw'
						fontWeight='bold'
						alignItems='center'
						paddingLeft='1.2vw'
						borderRadius='1vw'
					>
						Case Details
					</Flex>
					<Divider />
					{/* Row 2 */}
					<Flex alignItems='center' justifyContent='center' w='100%' overflow='auto'>
						<TableContainer>
							<Table variant='simple' overflow='auto' size={{ base: "sm", md: "md", lg: "lg" }}>
								<Thead>
									<Tr>
										<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>Case ID</Th>
										<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>Reimbursement ID</Th>
										<Th>
											<Select
												id='selectStatus'
												style={{ fontSize: "1vw", fontFamily: "Arial", fontWeight: "bold" }}
												variant='unstyled'
												placeholder='STATUS'
												onChange={statusChanged}
											>
												<option value='Reimbursed (Value TBD)'>STATUS (REIMBURSED-VALUE TBD)</option>
												<option value='Reimbursed'>STATUS (REIMBURSED)</option>
												<option value='Negotiating'>STATUS (NEGOTIATING)</option>
												<option value='Closed'>STATUS (CLOSED)</option>
											</Select>
										</Th>
										<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>
											<Select
												id='selectStore'
												style={{ fontSize: "1vw", fontFamily: "Arial", fontWeight: "bold" }}
												variant='unstyled'
												placeholder='STORE'
												onChange={storeChanged}
											>
												{store.length > 0 &&
													store.map((singleStoreInfo) => (
														<option key={singleStoreInfo.storeName} value={singleStoreInfo.storeName}>
															STORE ({singleStoreInfo.storeName})
														</option>
													))}
											</Select>
										</Th>
										<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>
											<Select
												id='selectValueOrder'
												style={{ fontSize: "1vw", fontFamily: "Arial", fontWeight: "bold" }}
												variant='unstyled'
												placeholder='VALUE'
												onChange={claimValueOrderChanged}
											>
												<option value='maxToMin'>VALUE (MAX-MIN)</option>
												<option value='minToMax'>VALUE (MIN-MAX)</option>
											</Select>
										</Th>
										<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>
											<Select
												id='selectQuantityOrder'
												style={{ fontSize: "1vw", fontFamily: "Arial", fontWeight: "bold" }}
												variant='unstyled'
												placeholder='QUANTITY'
												onChange={quantityOrderChanged}
											>
												<option value='maxToMin'>QUANTITY (MAX-MIN)</option>
												<option value='minToMax'>QUANTITY (MIN-MAX)</option>
											</Select>
										</Th>
										<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>
											<Select
												id='selectDateFiledOrder'
												style={{ fontSize: "1vw", fontFamily: "Arial", fontWeight: "bold" }}
												variant='unstyled'
												placeholder='DATE FILED'
												onChange={dateFiledOrderChanged}
											>
												<option value='aToZ'>DATE FILED (A-Z)</option>
												<option value='zToA'>DATE FILED (Z-A)</option>
											</Select>
										</Th>
									</Tr>
								</Thead>
								<Tbody>
									{pagiCases.length > 0 &&
										pagiCases.map((pagiCase) => <CaseTableItem key={pagiCase._id} userCase={pagiCase} />)}
								</Tbody>
							</Table>
						</TableContainer>
					</Flex>
					{/* Row 3, Pagination buttons */}
					<Flex w='100%' alignItems='center' justifyContent='center' borderRadius='1vw'>
						<Wrap spacing='1vw' justify='center' p='1vw'>
							<Button
								id='casePagiLeftArrow'
								onClick={() => paginationButtonClick(currentPage - 1 > 0 ? currentPage - 1 : 1)}
								size={{ base: "xs", md: "sm" }}
							>
								<Icon className='nav-items-icon' as={MdKeyboardArrowLeft} boxSize={3} />
							</Button>
							{genPagiBtns()}
							<Button
								id='casePagiRightArrow'
								onClick={() => paginationButtonClick(currentPage + 1 < totalPages ? currentPage + 1 : totalPages)}
								size={{ base: "xs", md: "sm" }}
							>
								<Icon className='nav-items-icon' as={MdKeyboardArrowRight} boxSize={3} />
							</Button>
						</Wrap>
					</Flex>
				</Flex>
			)}
		</Flex>
	);
};

export default CaseDetails;
