import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    loading: false,
    error: null,
    billings: [],
    pagiBillings: [],
    pagination: {
        'billingsPerPage': 10,
        'currentPage': 1,
        'totalPages': 0
    },
};

export const userBillingSlice = createSlice({
    name: 'userBilling',
    initialState,
    reducers: {
        setLoading: (state) => {
            state.error = null;
            state.loading = true;
        },
        setError: (state, { payload }) => {
            state.error = payload;
            state.loading = false;
        },
        setBillings: (state, { payload }) => {
            state.error = null;
            state.loading = false;
            state.billings = payload;
        },
        setPagiBillings: (state, { payload }) => {
            state.error = null;
            state.loading = false;
            state.pagiBillings = payload;
        },
        setPagination: (state, { payload }) => {
            state.error = null;
            state.loading = false;
            state.pagination = payload;
        }
    },
});

// The followings are actually generated action creators, not actions themselves.
// Action creators and action types are generated for each case reducer function.
export const {
    setLoading,
    setError,
    setBillings,
    setPagiBillings,
    setPagination
} = userBillingSlice.actions;

export default userBillingSlice.reducer;