import { Flex, Heading, Icon, Text, Stack, Box, Button, Input, Divider, Spacer } from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendResetEmail } from "../redux/actions/userActions";
import { LuArrowUpRightSquare } from "react-icons/lu";
import { setShowLogin, setShowSignup, setShowForgotPwd, stateReset, setError } from "../redux/slices/user";

const PasswordForgottenForm = () => {
	const dispatch = useDispatch();
	const [email, setEmail] = useState("");
	const handleChange = (event) => {
		setEmail(event.target.value);
	};
	const { serverMsg, serverStatus } = useSelector((state) => state.user);

	return serverMsg === null ? (
		<Flex w='100%' h='100%' overflow='hidden' alignItems='center' justifyContent='center' flexDir='column'>
			<Flex
				w='100%'
				h='90vh'
				alignItems='center'
				justifyContent='center'
				flexDir='column'
				marginTop={{ base: "2em", md: "5em", lg: "5em" }}
			>
				<Flex
					w='100%'
					alignItems='center'
					justifyContent='center'
					flexDir='column'
					marginBottom={{ base: "2em", md: "2em", lg: "2em" }}
				>
					<Heading fontSize={{ base: "xl", lg: "xl" }} marginBottom='0.5vw'>
						Enter your email address
					</Heading>
					<Text fontSize={{ base: "0.6em", lg: "0.8em" }}>
						We'll send you an email with a link to reset your password.
					</Text>
				</Flex>
				<Flex
					w='100%'
					alignItems='center'
					justifyContent='center'
					flexDir='column'
					marginBottom={{ base: "2em", md: "2em", lg: "2em" }}
					paddingLeft={{ base: "2em", md: "2em", lg: "2em" }}
					paddingRight={{ base: "2em", md: "2em", lg: "2em" }}
				>
					<Input
						mb='4'
						type='text'
						name='email'
						placeholder='Your Email Address'
						label='Email'
						value={email}
						onChange={(e) => handleChange(e)}
					/>
					<Button
						color='white'
						bgColor='rgba(79, 209, 197, 1)'
						w='100%'
						fontSize='md'
						onClick={() => {
							dispatch(stateReset());
							if (email) {
								dispatch(sendResetEmail(email));
							} else {
								dispatch(setError("Email address can not be empty."));
							}
						}}
					>
						Send reset email
					</Button>
					<Flex h='3vh' w='100%' marginTop='2vh' bgColor='rgba(0,0,0,0)' justifyContent='center' alignItems='center'>
						<Button
							variant='link'
							size={{ base: "sm", lg: "sm" }}
							fontWeight='400'
							color='rgba(0,0,0,0.8)'
							onClick={() => {
								dispatch(stateReset());
								dispatch(setShowLogin(true));
							}}
						>
							Sign in
						</Button>
						<Divider orientation='vertical' marginLeft='1vh' marginRight='1vh' />
						<Button
							variant='link'
							size={{ base: "sm", lg: "sm" }}
							fontWeight='400'
							color='rgba(0,0,0,0.8)'
							onClick={() => {
								dispatch(stateReset());
								dispatch(setShowSignup(true));
							}}
						>
							Sign up
						</Button>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	) : (
		<Flex w='100%' h='100%' overflow='auto' alignItems='center' justifyContent='center' flexDir='column'>
			<Flex
				w='100%'
				h='90vh'
				alignItems='center'
				justifyContent='center'
				paddingLeft='2vmax'
				paddingRight='2vmax'
				flexDir='column'
			>
				<Flex marginBottom='1vmax'>
					<Text fontSize='1.5vmax'>Email Sent To</Text>
					<Flex w='0.5vw'></Flex>
					<Text color='rgba(0, 0, 0, 0.8)' fontSize='1.5vmax'>
						{email}
					</Text>
				</Flex>
				<Flex color='rgba(0, 0, 0, 0.7)' fontSize='1vmax'>
					Please check your mailbox and use the link to reset your password.
				</Flex>
			</Flex>
		</Flex>
	);
};

export default PasswordForgottenForm;
