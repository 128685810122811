import React from "react";
import { Td, Tr } from "@chakra-ui/react";

const PaymentBankDetail = ({ item }) => {
	return (
		<>
			<Tr>
				<Td fontSize={{ base: "0.5rem", sm: "0.6rem", md: "0.7rem", lg: "0.9rem", xl: "1.1rem", "2xl": "1.4rem" }}>
					{item["us_bank_account"]["bank_name"]}
				</Td>
				<Td fontSize={{ base: "0.5rem", sm: "0.6rem", md: "0.7rem", lg: "0.9rem", xl: "1.1rem", "2xl": "1.4rem" }}>
					{item["us_bank_account"]["account_type"]}
				</Td>
				<Td fontSize={{ base: "0.5rem", sm: "0.6rem", md: "0.7rem", lg: "0.9rem", xl: "1.1rem", "2xl": "1.4rem" }}>
					{item["us_bank_account"]["routing_number"]}
				</Td>
				<Td fontSize={{ base: "0.5rem", sm: "0.6rem", md: "0.7rem", lg: "0.9rem", xl: "1.1rem", "2xl": "1.4rem" }}>
					{item["us_bank_account"]["last4"]}
				</Td>
			</Tr>
		</>
	);
};

export default PaymentBankDetail;
