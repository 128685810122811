import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentStatus from "../components/PaymentStatus";
import { useSelector } from "react-redux";
import { Flex } from "@chakra-ui/react";

const stripePromise = loadStripe(
	"pk_test_51PxEc7FDXKwE0gwcQmcxwM4mfkZw3Abur8b7EXFCyrelTvPFvsvB3uGhDAArjYzMmlbUTn4Lo3NFiYRMhuOTPTTv00mQBUbU9R"
);

const SavingCardResult = () => {
	return (
		<Flex w='42vw' h='100vh' justifyContent='center' alignItems='center' flexDir='column'>
			<Elements stripe={stripePromise}>
				<PaymentStatus />
			</Elements>
		</Flex>
	);
};

export default SavingCardResult;
