import "../Styles/globals.css";
import { Flex, useToast } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import ClientHeader from "../components/ClientHeader";
import ClientPayment from "../components/ClientPayment";
import { setCurNavTab } from "../redux/slices/user";

const PortalPaymentScreen = (req, res) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const toast = useToast();
	const { userInfo } = useSelector((state) => state.user);

	useEffect(() => {
		if (userInfo === null) {
			navigate("/portal");
		} else {
			dispatch(setCurNavTab("nav-payment"));
		}
	}, [dispatch, navigate, userInfo, toast]);

	return (
		// The viewport flexbox.'
		<Flex w='100vw' h='100vh' overflow='hidden' bgColor='rgba(242, 242, 242, 0.9)'>
			{/* Column 1 (Nav Bar) */}
			<ClientHeader />
			{/* Column 2 */}
			<ClientPayment />
		</Flex>
	);
};

export default PortalPaymentScreen;
