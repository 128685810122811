import axios from 'axios';
import {
    setLoading,
    setError,
    setInboundDocReqEvents,
    setInboundDocReqIndexedEvents,
    setInboundDocReqPagiEvents,
    setInboundDocReqWorkingEvents,
    setCurCreatedDateOrder,
    setCurDiscrepancyOrder,
    setDocUploaded,
    setPagination
} from '../slices/userInbound.js';
import { useSelector } from "react-redux";

export const getInboundReqDoc = (email) => async (dispatch, getState) => {
    async function indexingData(shipReqDoc) {
        dispatch(setInboundDocReqEvents(shipReqDoc));
        // Get the ascending order according to createDate
        const dateAscOrder = [...Array(shipReqDoc.length).keys()];
        // Order shipReqDoc according to createDate in the descending order
        // toReversed() is not supported on old time browsers. reverse() 
        // is supported more widely.
        const dateDescOrder = [...dateAscOrder].reverse()
        // Order shipReqDoc according to Discrepancy in the ascending order
        shipReqDoc = [...shipReqDoc];
        shipReqDoc.sort((element1, element2) => (element1['totalDiscrepancies'] - element2['totalDiscrepancies']))
        const disAscOrder = [];
        shipReqDoc.forEach((shipment) => {
            disAscOrder.push(shipment.index)
        });
        // Order shipReqDoc according to Discrepancy in the descending order
        // toReversed() is not supported on old time browsers. reverse() 
        // is supported more widely.
        const disDescOrder = [...disAscOrder].reverse();
        //
        const indexedShips = {
            dateAscOrder, dateDescOrder, disAscOrder, disDescOrder
        };
        dispatch(setInboundDocReqIndexedEvents(indexedShips));
        dispatch(setInboundDocReqWorkingEvents(dateAscOrder));
    }

    const { pagination: { eventsPerPage } } = getState().userInbound;
    try {
        const config = { headers: { 'Content-Type': 'application/json' }, params: { 'email': email } };
        const { data } = await axios.get(`/api/portal/inbound`, config);
        // Order data according to createDate in the ascending order
        data.sort((element1, element2) => {
            if (element1.createDate < element2.createDate) return -1;
            return 1;
        })
        data.forEach((shipment, idx) => {
            shipment.index = idx
        });
        if (data.length > 0) {
            const newTotalPages = Math.ceil(data.length / eventsPerPage);
            const paginatedEvents = data.slice(0, eventsPerPage);
            dispatch(setPagination({ eventsPerPage, 'currentPage': 1, 'totalPages': newTotalPages }));
            dispatch(setInboundDocReqPagiEvents(paginatedEvents));
            indexingData(data);
        }
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data
                    ? error.response.data
                    : error.message
                        ? error.message
                        : 'An unexpected error has occured. Please try again later.'
            )
        );
    }
}

export const getCustomizedShips = () => async (dispatch, getState) => {
    if (getState().userInbound.inboundDocReqEvents.length === 0) return;
    const { curCreatedDateOrder, curDiscrepancyOrder, inboundDocReqIndexedEvents } = getState().userInbound;
    let newOrder = inboundDocReqIndexedEvents.dateAscOrder;
    if (curCreatedDateOrder) {
        if (curCreatedDateOrder === 'asc') {
            newOrder = inboundDocReqIndexedEvents.dateAscOrder;
        } else {
            newOrder = inboundDocReqIndexedEvents.dateDescOrder;
        }
    } else if (curDiscrepancyOrder) {
        if (curDiscrepancyOrder === 'asc') {
            newOrder = inboundDocReqIndexedEvents.disAscOrder;
        } else {
            newOrder = inboundDocReqIndexedEvents.disDescOrder;
        }
    }
    console.log('newOrder:', newOrder);
    dispatch(setInboundDocReqWorkingEvents(newOrder));
    dispatch(getPagiEvents(1));
}

export const getPagiEvents = (clickedPage) => async (dispatch, getState) => {
    const curOrder = getState().userInbound.inboundDocReqWorkingEvents;
    const { inboundDocReqEvents, pagination: { eventsPerPage, totalPages } } = getState().userInbound;
    const startIndex = (clickedPage - 1) * eventsPerPage;
    const endIndex = startIndex + eventsPerPage;
    const paginatedEventIndices = curOrder.slice(startIndex, endIndex);
    const paginatedEvents = []
    paginatedEventIndices.forEach((eventIndex) => {
        paginatedEvents.push(inboundDocReqEvents[eventIndex])
    })
    dispatch(setPagination({ eventsPerPage, 'currentPage': clickedPage, totalPages }));
    dispatch(setInboundDocReqPagiEvents(paginatedEvents));
}

// export const uploadDoc = (shipment, fd, setMsg) => async (dispatch, getState) => {
//     const { email } = useSelector((state) => state.user.userInfo);

// }
