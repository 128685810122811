import {
	useDisclosure,
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalHeader,
	ModalCloseButton,
	ModalFooter,
	Text,
} from "@chakra-ui/react";

const PrivacyPolicy = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<>
			<Button variant='link' fontSize='1vw' fontWeight='400' color='rgba(0,0,0,0.8)' onClick={onOpen}>
				Privacy Policy
			</Button>

			<Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Modal Title</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text fontWeight='bold' mb='1rem'>
							You can scroll the content behind the modal
						</Text>
						<Text>
							For accessibility, it is recommended to block scrolling on the main document behind the modal. Chakra does
							this by default but you can set blockScrollOnMount to false to allow scrolling behind the modal.
						</Text>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme='blue' mr={3} onClick={onClose}>
							Close
						</Button>
						<Button variant='ghost'>Secondary Action</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

export default PrivacyPolicy;
