import "../Styles/globals.css";
import { Flex, Heading, Avatar, Text, Icon, Link, Button } from "@chakra-ui/react";
import { Link as ReactLink } from "react-router-dom";
import { FaRegChartBar } from "react-icons/fa";
import { LuArrowUpRightSquare } from "react-icons/lu";
import { LiaShippingFastSolid } from "react-icons/lia";
import { RiStoreLine } from "react-icons/ri";
import { RiCoinLine } from "react-icons/ri";
import { GoCreditCard } from "react-icons/go";
import { IoReceiptOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { setCurNavTab } from "../redux/slices/user";

const ClientHeader = () => {
	const dispatch = useDispatch();
	const { userInfo, curNavTab } = useSelector((state) => state.user);

	return (
		<Flex
			w='15vw'
			flexDir='column'
			borderRight='0.01rem solid rgba(0, 0, 0, 0.1)'
			alignItems='center'
			justifyContent='space-between'
			overflow='auto'
		>
			{/* Brand Name Flex	 */}
			<Flex alignItems='center' justifyContent='center' w='100%' as={ReactLink} to='/'>
				<Text
					mt='5vh'
					mb='5vh'
					alignSelf='center'
					letterSpacing='tight'
					color='rgba(79, 209, 197, 1)'
					fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
					fontFamily='Arial'
					fontWeight='600'
				>
					TinkerBoost
				</Text>
				<Flex
					marginLeft='0.2rem'
					fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
					color='rgba(79, 209, 197, 1)'
				>
					<Icon as={LuArrowUpRightSquare} />
				</Flex>
			</Flex>
			{/* Nav Flex */}
			<Flex
				flexDir='column'
				color='rgba(88, 88, 88, 0.9)'
				justifyContent='space-around'
				alignItems='center'
				w='100%'
				h='50vh'
				overflow='auto'
			>
				{/* Nav Item Flex */}
				<Flex
					className='nav-items'
					id='nav-dashboard'
					as={ReactLink}
					to='/portal/dashboard'
					onClick={() => dispatch(setCurNavTab("nav-dashboard"))}
					bgColor={curNavTab === "nav-dashboard" ? "rgba(190, 190, 190, 0.3)" : "rgba(190, 190, 190, 0)"}
					fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
				>
					<Icon className='nav-items-icon' as={FaRegChartBar} />
					<Text>Dashboard</Text>
				</Flex>
				{/* Nav Item Flex */}
				<Flex
					className='nav-items'
					id='nav-inbound'
					as={ReactLink}
					to='/portal/inbound'
					onClick={() => dispatch(setCurNavTab("nav-inbound"))}
					bgColor={curNavTab === "nav-inbound" ? "rgba(190, 190, 190, 0.3)" : "rgba(190, 190, 190, 0)"}
					fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
				>
					<Icon className='nav-items-icon' as={LiaShippingFastSolid} />
					<Text>Inbound Shipment</Text>
				</Flex>
				{/* Nav Item Flex */}
				<Flex
					className='nav-items'
					id='nav-store'
					as={ReactLink}
					to='/portal/store'
					onClick={() => dispatch(setCurNavTab("nav-store"))}
					bgColor={curNavTab === "nav-store" ? "rgba(190, 190, 190, 0.3)" : "rgba(190, 190, 190, 0)"}
					fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
				>
					<Icon className='nav-items-icon' as={RiStoreLine} />
					<Text>Store</Text>
				</Flex>
				{/* Nav Item Flex */}
				<Flex
					className='nav-items'
					id='nav-credit'
					as={ReactLink}
					to='/portal/credit'
					onClick={() => dispatch(setCurNavTab("nav-credit"))}
					bgColor={curNavTab === "nav-credit" ? "rgba(190, 190, 190, 0.3)" : "rgba(190, 190, 190, 0)"}
					fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
				>
					<Icon className='nav-items-icon' as={RiCoinLine} />
					<Text>Credit</Text>
				</Flex>
				{/* Nav Item Flex */}
				<Flex
					className='nav-items'
					id='nav-payment'
					as={ReactLink}
					to='/portal/payment'
					onClick={() => dispatch(setCurNavTab("nav-payment"))}
					bgColor={curNavTab === "nav-payment" ? "rgba(190, 190, 190, 0.3)" : "rgba(190, 190, 190, 0)"}
					fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
				>
					<Icon className='nav-items-icon' as={GoCreditCard} />
					<Text>Payment Method</Text>
				</Flex>
				{/* Nav Item Flex */}
				{/* <Flex
					className='nav-items'
					id='nav-billing'
					as={ReactLink}
					to='/portal/billing'
					onClick={() => dispatch(setCurNavTab("nav-billing"))}
					bgColor={curNavTab === "nav-billing" ? "rgba(190, 190, 190, 0.3)" : "rgba(190, 190, 190, 0)"}
					fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
				>
					<Icon className='nav-items-icon' as={IoReceiptOutline} />
					<Text>Billing</Text>
				</Flex> */}
			</Flex>
			{/* User Flex */}
			<Flex alignItems='center' justifyContent='center' w='100%' paddingBottom='1rem'>
				<Flex
					borderRadius='0.4px'
					alignItems='center'
					justifyContent='center'
					bgColor='rgba(190, 190, 190, 0.3)'
					w='90%'
					// padding='0.5rem'
				>
					<Avatar w='1.5vw' h='1.5vw' margin='1rem' />
					<Text
						fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "0.9rem", xl: "1rem", "2xl": "1.2rem" }}
						fontWeight='600'
						color='rgba(88, 88, 88, 0.9)'
					>
						{userInfo ? userInfo.name : ""}
					</Text>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default ClientHeader;
