import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    error: null,
    toSaveCases: {},
    toUpdateCases: {},
    toRemoveEvents: {},
    addedCaseID: {}
};

export const adminCaseSlice = createSlice({
    name: 'adminCase',
    initialState,
    reducers: {
        setError: (state, { payload }) => {
            state.error = payload;
        },
        setToSaveCases: (state, { payload }) => {
            state.error = null;
            state.toSaveCases[payload.fnsku] = payload;
        },
        setResetToSaveCases: (state) => {
            state.error = null;
            state.toSaveCases = {};
        },
        setToUpdateCases: (state, { payload }) => {
            state.error = null;
            state.toUpdateCases[payload.fnsku] = payload;
        },
        setToRemoveEvents: (state, { payload }) => {
            state.error = null;
            state.toRemoveEvents[payload] = null;
        },
        setResetToRemoveEvents: (state) => {
            state.error = null;
            state.toRemoveEvents = {};
        },
        setAddedCaseID: (state, { payload }) => {
            state.error = null;
            state.addedCaseID[payload.caseID] = payload.fnsku;
        },
    },
});

export const {
    setError,
    setToSaveCases,
    setToUpdateCases,
    setToRemoveEvents,
    setAddedCaseID,
    setResetToSaveCases,
    setResetToRemoveEvents
} = adminCaseSlice.actions;

export default adminCaseSlice.reducer;