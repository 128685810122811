import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    loading: false,
    error: null,
    clientCases: [],
    indexedCases: {},
    workingCases: [],
    pagiCases: [],
    pagination: { casesPerPage: 10, currentPage: 1, totalPages: 0 },
    toUpdateCases: {},
    toCreateCases: {},
    toDeleteCases: {}
};

export const adminClientCaseSlice = createSlice({
    name: 'adminClientCase',
    initialState,
    reducers: {
        setClientCaseError: (state, { payload }) => {
            state.error = payload;
        },
        setClientCases: (state, { payload }) => {
            state.clientCases = payload;
            state.workingCases = payload;
        },
        setClientIndexedCases: (state, { payload }) => {
            state.indexedCases = payload;
        },
        setClientWorkingCases: (state, { payload }) => {
            state.workingCases = payload;
        },
        setClientPagiCases: (state, { payload }) => {
            state.pagiCases = payload;
        },
        setClientPagination: (state, { payload }) => {
            state.pagination = payload;
        },
    },
});

export const {
    setClientCaseError,
    setClientCases,
    setClientIndexedCases,
    setClientPagiCases,
    setClientPagination,
    setClientWorkingCases
} = adminClientCaseSlice.actions;

export default adminClientCaseSlice.reducer;