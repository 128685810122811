import { Flex, Heading, HStack, Text, Button, FormControl } from "@chakra-ui/react";
import { Formik } from "formik";
import * as Yup from "yup";
import TextField from "../components/TextField";
import PasswordField from "../components/PasswordField";
import { login } from "../redux/actions/userActions";
import { Link as ReactLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { stateReset, setShowSignup, setShowForgotPwd, setError } from "../redux/slices/user";
import { googleLogin } from "../redux/actions/userActions";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { FcGoogle } from "react-icons/fc";

const Login = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const redirect = "/dashboard";

	const { loading, error, userInfo, serverMsg, showLogin, showSignup, showForgotPwd } = useSelector(
		(state) => state.user
	);

	const handleGoogleLogin = useGoogleLogin({
		onSuccess: async (response) => {
			const userInfo = await axios
				.get("https://www.googleapis.com/oauth2/v3/userinfo", {
					headers: { Authorization: `Bearer ${response.access_token}` },
				})
				.then((res) => res.data);
			const { sub, email, name, picture } = userInfo;
			dispatch(googleLogin(sub, email, name, picture));
		},
	});

	return (
		<Formik
			initialValues={{ email: "", password: "" }}
			validationSchema={Yup.object({
				email: Yup.string().email("Invalid email.").required("An email address is required."),
				password: Yup.string()
					.min(8, "Password is too short - must contain at least 8 characters.")
					.matches(
						/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
						"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
					)
					.required("Password is required."),
			})}
			onSubmit={(values) => {
				dispatch(stateReset());
				dispatch(login(values.email, values.password));
			}}
		>
			{(formik) => (
				<Flex flexDir='column' h='90vh' w='100%' alignItems='center' justifyContent='center'>
					<Flex
						flexDir='column'
						alignItems='center'
						justifyContent='space-between'
						w='100%'
						marginTop={{ base: "0.5em", md: "1em", lg: "1.5em" }}
						marginBottom={{ base: "1em", md: "1em", lg: "1.5em" }}
					>
						<Heading fontSize={{ base: "xl", lg: "xl" }}>Log in</Heading>
						<HStack spacing='1' justify='center'>
							<Text fontSize={{ base: "0.8em", lg: "0.8em" }}>Don't have an account?</Text>
							<Button
								as={ReactLink}
								variant='link'
								color='rgba(79, 209, 197, 1)'
								size={{ base: "sm", lg: "sm" }}
								onClick={() => {
									dispatch(stateReset());
									dispatch(setShowSignup(true));
								}}
							>
								Sign up
							</Button>
						</HStack>
					</Flex>
					<Flex
						flexDir='column'
						as='form'
						onSubmit={formik.handleSubmit}
						w='100%'
						marginBottom={{ base: "1em", md: "1em", lg: "1.5em" }}
					>
						<Flex w='100%' paddingLeft='20%' paddingRight='20%' justifyContent='center' alignItems='center'>
							<FormControl>
								<TextField type='text' name='email' placeholder='you@example.com' label='Email' />
								<PasswordField type='password' name='password' placeholder='your password' label='Password' />
							</FormControl>
						</Flex>
						<Flex flexDir='column' alignItems='center' justifyContent='center'>
							<Button
								color='white'
								bgColor='rgba(79, 209, 197, 1)'
								w='60%'
								fontSize='md'
								isLoading={loading}
								type='submit'
								marginBottom={{ base: "1em", md: "1em", lg: "1em" }}
							>
								Sign in
							</Button>
							<Button
								leftIcon={<FcGoogle />}
								color='white'
								bgColor='rgba(79, 209, 197, 1)'
								w='60%'
								fontSize='md'
								isLoading={loading}
								onClick={() => handleGoogleLogin()}
							>
								Google sign in
							</Button>
							<Button
								as={ReactLink}
								variant='link'
								size={{ base: "sm", lg: "sm" }}
								fontWeight='400'
								color='black'
								marginTop='2%'
								onClick={() => {
									dispatch(stateReset());
									dispatch(setShowForgotPwd(true));
								}}
							>
								Forgot password?
							</Button>
						</Flex>
					</Flex>
				</Flex>
			)}
		</Formik>
	);
};

export default Login;
