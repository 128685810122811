import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Button,
	Divider,
	Spinner,
	Stack,
	TableContainer,
	Table,
	Tbody,
	Th,
	Thead,
	Tr,
	Wrap,
	Flex,
	Icon,
	Select,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { getPagiEvents } from "../redux/actions/inboundDocReqActions.js";
import ShipTableItem from "./ShipTableItem.jsx";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { setCurCreatedDateOrder, setCurDiscrepancyOrder } from "../redux/slices/userInbound.js";
import { getCustomizedShips } from "../redux/actions/inboundDocReqActions.js";

const InboundShipDetail = () => {
	const dispatch = useDispatch();

	const {
		loading,
		error,
		inboundDocReqPagiEvents,
		pagination: { totalPages, currentPage },
	} = useSelector((state) => state.userInbound);

	const paginationButtonClick = (currentPage) => {
		dispatch(getPagiEvents(currentPage));
	};

	// Generate paginated buttons in accordance with users input.
	const genPagiBtns = () => {
		const allLeadBtns = () => {
			const buttons = [];
			for (let i = 1; i < currentPage; i++) {
				buttons.push(
					<Button key={i} onClick={() => paginationButtonClick(i)} size='sm'>
						{i}
					</Button>
				);
			}
			return buttons;
		};

		const allLastBtns = () => {
			const btns = [];
			for (let i = currentPage + 1; i <= totalPages; i++) {
				btns.push(
					<Button key={i} onClick={() => paginationButtonClick(i)} size='sm'>
						{i}
					</Button>
				);
			}
			return btns;
		};

		return (
			<>
				{currentPage - 3 > 1 ? (
					<>
						<Button key={1} onClick={() => paginationButtonClick(1)} size='sm'>
							1
						</Button>
						...
						<Button key={currentPage - 2} onClick={() => paginationButtonClick(currentPage - 2)} size='sm'>
							{currentPage - 2}
						</Button>
						<Button key={currentPage - 1} onClick={() => paginationButtonClick(currentPage - 1)} size='sm'>
							{currentPage - 1}
						</Button>
					</>
				) : (
					allLeadBtns()
				)}
				<Button colorScheme='teal' key={currentPage} onClick={() => paginationButtonClick(currentPage)} size='sm'>
					{currentPage}
				</Button>
				{currentPage + 3 < totalPages ? (
					<>
						<Button key={currentPage + 1} onClick={() => paginationButtonClick(currentPage + 1)} size='sm'>
							{currentPage + 1}
						</Button>
						<Button key={currentPage + 2} onClick={() => paginationButtonClick(currentPage + 2)} size='sm'>
							{currentPage + 2}
						</Button>
						...
						<Button key={totalPages} onClick={() => paginationButtonClick(totalPages)} size='sm'>
							{totalPages}
						</Button>
					</>
				) : (
					allLastBtns()
				)}
			</>
		);
	};

	const dateOrderChanged = (newOrder) => {
		dispatch(setCurCreatedDateOrder(newOrder));
		dispatch(getCustomizedShips());
	};

	const disOrderChanged = (newOrder) => {
		dispatch(setCurDiscrepancyOrder(newOrder));
		dispatch(getCustomizedShips());
	};

	return (
		// The overall wrapper
		<Flex
			className='component-dashboard'
			alignItems='center'
			justifyContent='flex-start'
			w={{ base: "96%", md: "97%" }}
			marginTop='1.5em'
			flexDir='column'
			borderRadius='1vw'
			bgColor='white'
		>
			{error && (
				<Alert status='error'>
					<AlertIcon />
					<AlertTitle>Oops!</AlertTitle>
					<AlertDescription>{error}</AlertDescription>
				</Alert>
			)}
			{loading ? (
				<Wrap justify='center'>
					<Stack direction='row' spacing='4'>
						<Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='teal.500' size='xl' />
					</Stack>
				</Wrap>
			) : (
				<Flex alignItems='center' justifyContent='flex-start' w='100%' minH='50em' flexDir='column' borderRadius='1vw'>
					{/* Row 1 */}
					<Flex
						// bgColor='white'
						w='100%'
						h='5em'
						fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
						fontWeight='bold'
						alignItems='center'
						paddingLeft='1.2vw'
						borderRadius='1vw'
					>
						Shipments Requiring Documents
					</Flex>
					<Divider />
					{/* Row 2 */}
					<Flex alignItems='center' justifyContent='center' w='100%' overflow='auto'>
						<TableContainer>
							<Table variant='simple' overflow='auto' size={{ base: "sm", md: "md" }}>
								<Thead>
									<Tr>
										<Th
											fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
											fontFamily='Arial'
										>
											STORE
										</Th>
										<Th
											fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
											fontFamily='Arial'
										>
											Shipment Id
										</Th>
										<Th
										// fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
										// fontFamily='Arial'
										>
											<Select
												id='createdDate'
												fontWeight='bold'
												fontFamily='Arial'
												fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
												variant='unstyled'
												placeholder='CREATED DATE'
												onChange={(e) => dateOrderChanged(e.target.value)}
											>
												<option value='asc'>CREATED DATE (ASCENDING ORDER)</option>
												<option value='des'>CREATED DATE (DESCENDING ORDER)</option>
											</Select>
										</Th>

										<Th
										// fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
										// fontFamily='Arial'
										>
											<Select
												id='discrepancy'
												fontWeight='bold'
												fontFamily='Arial'
												fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
												variant='unstyled'
												placeholder='DISCREPANCIES'
												onChange={(e) => disOrderChanged(e.target.value)}
											>
												<option value='asc'>DISCREPANCIES (MIN TO MAX)</option>
												<option value='des'>DISCREPANCIES (MAX TO MIN)</option>
											</Select>
										</Th>
									</Tr>
								</Thead>
								<Tbody>
									{inboundDocReqPagiEvents.length > 0 &&
										inboundDocReqPagiEvents.map((shipment) => <ShipTableItem key={shipment._id} shipment={shipment} />)}
								</Tbody>
							</Table>
						</TableContainer>
					</Flex>
					{/* Row 3, Pagination buttons */}
					<Flex
						w='100%'
						alignItems='center'
						justifyContent='center'
						borderRadius='1vw'
						marginTop={{ base: "2em", md: "2em" }}
					>
						<Wrap spacing='1vw' justify='center' p='1vw'>
							<Button
								id='casePagiLeftArrow'
								onClick={() => paginationButtonClick(currentPage - 1 > 0 ? currentPage - 1 : 1)}
								size='sm'
							>
								<Icon className='nav-items-icon' as={MdKeyboardArrowLeft} boxSize={3} />
							</Button>
							{genPagiBtns()}
							<Button
								id='casePagiRightArrow'
								onClick={() => paginationButtonClick(currentPage + 1 < totalPages ? currentPage + 1 : totalPages)}
								size='sm'
							>
								<Icon className='nav-items-icon' as={MdKeyboardArrowRight} boxSize={3} />
							</Button>
						</Wrap>
					</Flex>
				</Flex>
			)}
		</Flex>
	);
};

export default InboundShipDetail;
