import { Flex, Icon, Text, Link } from "@chakra-ui/react";
import { LiaCopyrightSolid } from "react-icons/lia";
import { RxDividerVertical } from "react-icons/rx";
import PrivacyPolicy from "./PrivacyPolicy";
import Terms from "./Terms";

const Footer = () => {
	return (
		<Flex
			w='100%'
			h='100%'
			borderRadius='1vw'
			alignItems='center'
			justifyContent='space-between'
			paddingLeft='1vw'
			paddingRight='1vw'
		>
			<Flex alignItems='center'>
				{/* Icon */}
				<Icon className='nav-items-icon' as={LiaCopyrightSolid} w='1vw' h='1vw' />
				{/* Description 1*/}
				<Text fontSize={{ base: "0.6em", lg: "0.8em" }}>2024 TinkerBoost</Text>
			</Flex>
			<Flex alignItems='center'>
				<Link href='https://www.termsfeed.com/live/5eb2dcdd-f27d-432d-8356-50158f4447cd' isExternal>
					<Text fontSize={{ base: "0.6em", lg: "0.8em" }}>Privacy Policy</Text>
				</Link>
				<Icon className='nav-items-icon' as={RxDividerVertical} w='1vw' h='1vw' />
				<Link href='https://www.termsfeed.com/live/0c2eb5e9-c3b6-4993-a647-13ae528e8769' isExternal>
					<Text fontSize={{ base: "0.6em", lg: "0.8em" }}>Terms of Use</Text>
				</Link>
			</Flex>
			<Flex>
				<Text fontSize={{ base: "0.6em", lg: "0.8em" }}>United States</Text>
			</Flex>
		</Flex>
	);
};

export default Footer;
