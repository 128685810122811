import React from "react";
import {
	Td,
	Tr,
	Button,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	TableContainer,
	Table,
	Thead,
	Th,
	Tbody,
	Flex,
	Text,
	Textarea,
	Icon,
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
} from "@chakra-ui/react";
import ShipTableItemDetail from "./ShipTableItemDetail";
import { IoCloudUploadOutline } from "react-icons/io5";
import { FaRegCommentDots } from "react-icons/fa";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { MdOutlineCheckBox } from "react-icons/md";
import { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setDocRequired } from "../redux/slices/userInbound.js";

const ShipTableItem = ({ shipment }) => {
	const dispatch = useDispatch();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const btnRef = React.useRef(null);
	const [files, setFiles] = useState(null);
	// const [progress, setProgress] = useState({ started: false, percentage: 0 });
	const [msg, setMsg] = useState(null);
	const [fileNames, setFileNames] = useState(null);
	const [commentMsg, setCommentMsg] = useState(null);
	const [comment, setComment] = useState("");
	const { email } = useSelector((state) => state.user.userInfo);
	const { docRequired } = useSelector((state) => state.userInbound.inboundDocReqEvents[shipment["index"]]);

	function handleUpload() {
		if (!files) {
			setMsg("No file selected");
			return;
		}
		const fd = new FormData();
		for (let index = 0; index < files.length; index++) {
			fd.append(`files${index + 1}`, files[index]);
		}
		setMsg("Uploading...");
		// setProgress((prevState) => {
		// 	return { ...prevState, started: true };
		// });
		axios
			.post("/api/portal/inbound-upload", fd, {
				// onUploadProgress: (progressEvent) => {
				//     setProgress((prevState) => {
				//         return { ...prevState, percentage: progressEvent.progress * 100 };
				//     });
				//     // console.log(progressEvent.progress * 100);
				// },
				headers: {
					store: shipment["store"],
					shipid: shipment["shipID"],
					email: email,
					mskus: shipment["MSKUs"],
				},
			})
			.then((res) => {
				setMsg("Upload successful");
				dispatch(setDocRequired({ index: shipment["index"], val: false }));
			})
			.catch((err) => {
				setMsg("Upload failed");
			});
	}

	let handleInputChange = (e) => {
		let inputValue = e.target.value;
		setComment(inputValue);
	};

	function handleComment() {
		// how to do error handling here?
		if (!comment) {
			setCommentMsg("No input comment");
			return;
		}
		axios
			.post(
				"/api/portal/inbound-comment",
				{ comment },
				{
					headers: {
						"Content-Type": "application/json",
						store: shipment["store"],
						shipid: shipment["shipID"],
						email: email,
						mskus: shipment["MSKUs"],
						comment,
					},
				}
			)
			.then((res) => {
				setCommentMsg("Saved");
				dispatch(setDocRequired({ index: shipment["index"], val: false }));
			})
			.catch((err) => {
				setCommentMsg("Save failed");
			});
	}

	return (
		<>
			<Tr>
				<Td fontSize={{ sm: "0.6rem", md: "0.7rem", lg: "0.9rem", xl: "1.1rem", "2xl": "1.4rem" }}>
					{shipment["store"]}
				</Td>
				<Td fontSize={{ sm: "0.6rem", md: "0.7rem", lg: "0.9rem", xl: "1.1rem", "2xl": "1.4rem" }}>
					{shipment["shipID"]}
				</Td>
				<Td fontSize={{ sm: "0.6rem", md: "0.7rem", lg: "0.9rem", xl: "1.1rem", "2xl": "1.4rem" }}>
					{shipment["createDate"]}
				</Td>
				<Td fontSize={{ sm: "0.6rem", md: "0.7rem", lg: "0.9rem", xl: "1.1rem", "2xl": "1.4rem" }}>
					{shipment["totalDiscrepancies"]}
				</Td>
				<Td>
					<Flex fontSize={{ sm: "0.6rem", md: "0.7rem", lg: "0.9rem", xl: "1.1rem", "2xl": "1.4rem" }}>
						<Button colorScheme='teal' size={{ md: "xs", lg: "md" }} variant='outline' ref={btnRef} onClick={onOpen}>
							Upload
						</Button>
						<Flex alignItems='center' justifyContent='center' marginLeft={{ base: "1vw" }}>
							{docRequired ? (
								<Icon as={MdOutlineCheckBoxOutlineBlank} boxSize={5} color='teal' />
							) : (
								<Icon as={MdOutlineCheckBox} boxSize={5} color='teal' />
							)}
						</Flex>
					</Flex>
				</Td>
			</Tr>
			<Modal onClose={onClose} finalFocusRef={btnRef} isOpen={isOpen} scrollBehavior='inside' size='full'>
				<ModalOverlay />
				<ModalContent>
					{/* <ModalHeader>Shipment Discrepancy</ModalHeader> */}
					<ModalCloseButton />
					<ModalBody>
						<Text fontSize={{ base: "1vw", md: "1.5vw" }} fontWeight='bold' marginBottom='2vw'>
							Step 1. View discrepancies
						</Text>
						<TableContainer whiteSpace='wrap'>
							<Table size='sm'>
								<Thead>
									<Tr>
										<Th>MSKU</Th>
										<Th>Title</Th>
										<Th>ASIN</Th>
										<Th>FNSKU</Th>
										<Th isNumeric>EXPECTED</Th>
										<Th isNumeric>RECEIVED</Th>
										<Th isNumeric>DISCREPANCY</Th>
									</Tr>
								</Thead>
								<Tbody>
									{shipment.items.length > 0 &&
										shipment.items.map((item) => <ShipTableItemDetail key={item._id} item={item} />)}
								</Tbody>
							</Table>
						</TableContainer>
						<Flex marginTop={{ base: "1vw", md: "2vw" }} flexDirection='column'>
							<Text fontSize={{ base: "1vw", md: "1.5vw" }} fontWeight='bold' marginBottom='2vw'>
								Step 2. Upload support documents
							</Text>
							<input
								onChange={(e) => {
									setFileNames(null);
									const fileInfo = [];
									for (let index = 0; index < e.target.files.length; index++) {
										fileInfo.push(e.target.files[index].name);
									}
									setFiles(e.target.files);
									setFileNames(fileInfo);
								}}
								type='file'
								accept='application/pdf'
								multiple
							/>
							{fileNames &&
								fileNames.map((fileName) => {
									return <span>{fileName}</span>;
								})}
							<Button
								marginTop={{ base: "1vw", md: "2vw" }}
								marginBottom={{ base: "0.5vw", md: "0.5vw" }}
								rightIcon={<IoCloudUploadOutline />}
								colorScheme='teal'
								w='20%'
								size='xs'
								onClick={handleUpload}
							>
								Upload
							</Button>
							{msg && <span style={{ color: "rgba(128, 128, 128, 0.8)", fontSize: "1.5vw" }}>{msg}</span>}
							<Accordion allowToggle marginTop={{ base: "1vw", md: "2vw" }}>
								<AccordionItem>
									<h2>
										<AccordionButton>
											<Box as='span' flex='1' textAlign='left'>
												Document Requirement (Reseller/ Aggregator)
											</Box>
											<AccordionIcon />
										</AccordionButton>
									</h2>
									<AccordionPanel pb={4}>
										<Text>Please send the proof of purchase for all missing items in this shipment.</Text>{" "}
										<Text>
											{" "}
											Proof of purchase can be the invoice from the supplier or manufacturer and include the following
											information:{" "}
										</Text>
										<Text>1. Date of the purchase.</Text>
										<Text>2. Product names of the missing items.</Text>
										<Text>3. Quantity purchased.</Text>
										<Text>
											The buying price isn’t required and can be concealed. Ensure that you provide invoices for all of
											the units that you have shipped from the invoice date to the shipped date of the shipments that
											are currently under investigation. We do not accept pro forma invoices or similar contracts as
											proof of ownership because they don't demonstrate a completed purchase.
										</Text>
										<Text>
											Note that we only accept documents in PDF format. We therefore ask you to provide us with a
											scanned copy or picture of the original invoice in PDF format.
										</Text>
									</AccordionPanel>
								</AccordionItem>

								<AccordionItem>
									<h2>
										<AccordionButton>
											<Box as='span' flex='1' textAlign='left'>
												Document Requirement (Private Label/ Brand Owner/ Manufacturer)
											</Box>
											<AccordionIcon />
										</AccordionButton>
									</h2>
									<AccordionPanel pb={4}>
										<Text>
											Please send a copy of the signed packing slip which including all missing items in this shipment.
											The packing slip must include the following information:
										</Text>
										<Text>1. Packing list date that is on or before the date of shipment.</Text>
										<Text>2. Shipment or purchase order ID.</Text>
										<Text>3. Product names of the missing items.</Text>
										<Text>4. Quantity shipped.</Text>
										<Text>
											Note that we only accept documents in PDF format. We therefore ask you to provide us with a
											scanned copy or picture of the original packing slip in PDF format.
										</Text>
									</AccordionPanel>
								</AccordionItem>
							</Accordion>
						</Flex>
						<Flex marginTop={{ base: "1vw", md: "2vw" }} flexDirection='column'>
							<Text fontSize={{ base: "1vw", md: "1.5vw" }} fontWeight='bold' marginBottom='2vw'>
								Step 3. Provide additional information (optional)
							</Text>
							<Textarea
								value={comment}
								onChange={handleInputChange}
								placeholder='If there is anything about this shipment you want to inform us, please leave your comment here.'
								size='sm'
							/>
							<Button
								marginTop={{ base: "1vw", md: "2vw" }}
								marginBottom={{ base: "0.5vw", md: "0.5vw" }}
								rightIcon={<FaRegCommentDots />}
								colorScheme='teal'
								w='20%'
								size='xs'
								onClick={handleComment}
							>
								Leave Comment
							</Button>
							{commentMsg && <span style={{ color: "rgba(128, 128, 128, 0.8)", fontSize: "1.5vw" }}>{commentMsg}</span>}
						</Flex>
					</ModalBody>
					{/* <ModalFooter>
						<Button onClick={onClose}>Close</Button>
					</ModalFooter> */}
				</ModalContent>
			</Modal>
		</>
	);
};

export default ShipTableItem;
