import "../Styles/globals.css";
import "../Styles/landingScreen.css";
import { Flex } from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator } from "@chakra-ui/react";
import MainHeaderColor from "../components/MainHeaderColor";
import AdminCase from "../components/AdminCase";
import AdminEvent from "../components/AdminEvent";

const CaseAdminScreen = () => {
	return (
		<div width='100vw'>
			{/* First part: Header. */}
			<Flex position='fixed' w='100%' h={{ base: "3em", md: "3em", lg: "3em" }}>
				<MainHeaderColor />
			</Flex>
			{/* Second part: case admin panel */}
			<Flex
				paddingTop={{ base: "6em", md: "6em", lg: "6em" }}
				paddingLeft={{ base: "2em", md: "2em", lg: "2em" }}
				paddingRight={{ base: "2em", md: "2em", lg: "2em" }}
				marginBottom={{ base: "2em", md: "2em", lg: "2em" }}
				w='100%'
				minH={{ base: "30em", md: "30em", lg: "30em" }}
				bgColor='rgba(242, 242, 242, 0.9)'
			>
				<Tabs variant='unstyled' w='100%'>
					<TabList>
						<Tab>Events</Tab>
						<Tab>Cases</Tab>
					</TabList>
					<TabIndicator mt='-1.5px' height='2px' bg='teal' borderRadius='1px' />
					<TabPanels>
						{/* Events Tab */}
						<TabPanel paddingLeft='4em'>
							<AdminEvent />
						</TabPanel>
						{/* Case Tab */}
						<TabPanel>
							<AdminCase />
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Flex>
		</div>
	);
};

export default CaseAdminScreen;
