import "../Styles/landingScreen.css";
import { Text, useToast, Flex, Heading, Icon } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as ReactLink, useLocation, useNavigate } from "react-router-dom";
import Login from "../components/Login";
import Signup from "../components/Signup";
import PasswordForgottenForm from "../components/PasswordForgottenForm";
import { LuArrowUpRightSquare } from "react-icons/lu";

const PortalScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const redirect = "/portal/dashboard";
	const toast = useToast();

	const { loading, error, userInfo, serverMsg, showLogin, showSignup, showForgotPwd } = useSelector(
		(state) => state.user
	);

	useEffect(() => {
		if (userInfo) {
			if (location.state?.from) {
				navigate(location.state.from);
			} else {
				toast({
					description: `You are logged in.`,
					status: "success",
					isClosable: true,
				});
				navigate(redirect);
			}
		}

		if (serverMsg) {
			toast({
				description: `${serverMsg}`,
				status: "success",
				isClosable: true,
			});
		}

		if (error) {
			toast({
				description: `${error}`,
				status: "error",
				isClosable: true,
			});
		}
	}, [userInfo, redirect, error, navigate, location.state, toast, serverMsg]);

	return (
		<Flex
			w={{ base: "100vw", md: "100vw", lg: "100vw" }}
			h={{ base: "220vh", md: "100vh", lg: "100vh" }}
			overflow='hidden'
			alignItems='center'
			justifyContent='center'
			flexDir={{ base: "column", md: "row", lg: "row" }}
		>
			{/* The login/ signup/ forgot password part */}
			<Flex
				w={{ base: "100%", md: "50%", lg: "50%" }}
				h={{ base: "30em", md: "100vh", lg: "100vh" }}
				overflow='auto'
				alignItems='center'
				justifyContent='space-between'
				flexDir='column'
				marginBottom={{ base: "2em", md: "2em", lg: "2em" }}
			>
				<Flex
					alignItems='center'
					paddingLeft={{ base: "1em", md: "1em", lg: "1em" }}
					paddingTop={{ base: "1em", md: "2em", lg: "2em" }}
					paddingBottom={{ base: "1em", md: "1em", lg: "1em" }}
					// paddingTop={{ base: "1vw", md: "1vw", lg: "1vw" }}
					// paddingBottom={{ base: "1vw", md: "1vw", lg: "1vw" }}
					marginBottom={{ base: "2em", md: "2em", lg: "2em" }}
					w='100%'
					// h={{ base: "10vh", md: "10vh", lg: "25vh" }}
					as={ReactLink}
					to='/'
				>
					<Heading
						alignSelf='center'
						color='rgba(79, 209, 197, 1)'
						fontSize={{ base: "1.3em", md: "1.3em", lg: "1.3em" }}
						fontFamily='Arial'
						letterSpacing='tight'
					>
						TinkerBoost
					</Heading>
					<Flex marginLeft='0.3vmin' color='rgba(79, 209, 197, 1)'>
						<Icon as={LuArrowUpRightSquare} boxSize={{ base: "1.3em", md: "1.3em", lg: "1.3em" }} />
					</Flex>
				</Flex>
				{showLogin ? <Login /> : showSignup ? <Signup /> : <PasswordForgottenForm />}
			</Flex>

			{/* The advertising part */}
			<Flex
				className='landing-body'
				w={{ base: "100%", md: "50%", lg: "50%" }}
				h={{ base: "30em", md: "100vh", lg: "100vh" }}
				overflow='hidden'
				alignItems='center'
				justifyContent='center'
				flexDir='column'
				p='2em'
			>
				<Text
					color='white'
					fontFamily='Arial'
					fontSize='8vmin'
					fontWeight='600'
					marginBottom={{ base: "1em", md: "0.5em", lg: "0.5em" }}
				>
					Commited to provide Best Service.
				</Text>
				<Text color='white' fontFamily='Arial'>
					TinkerBoost monitors your account constantly. Any identified reimbursable event will have the case filed in
					less than one business day, making sure you get the reimbursement as soon as possible.
				</Text>
			</Flex>
		</Flex>
	);
};

export default PortalScreen;
