import "../Styles/globals.css";
import { Flex, useToast } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import { getUserCases } from "../redux/actions/caseActions";
import { setCurNavTab } from "../redux/slices/user";
import ClientDashboard from "../components/ClientDashboard";
import ClientHeader from "../components/ClientHeader";

const ClientPortalDashboardScreen = (req, res) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const toast = useToast();
	const { userInfo } = useSelector((state) => state.user);

	useEffect(() => {
		if (userInfo === null) {
			navigate("/portal");
		} else {
			dispatch(setCurNavTab("nav-dashboard"));
			dispatch(getUserCases(userInfo.email));
		}
	}, [dispatch, navigate, userInfo, toast]);

	return (
		// The viewport flexbox.'
		<Flex h='100vh' w='100vw' overflow='hidden' bgColor='rgba(242, 242, 242, 0.9)'>
			{/* Column 1 (Nav Bar) */}
			<ClientHeader />
			{/* Column 2 */}
			<ClientDashboard />
		</Flex>
	);
};

export default ClientPortalDashboardScreen;
