import React from "react";
import { Td, Tr } from "@chakra-ui/react";

const ShipTableItemDetail = ({ item }) => {
	return (
		<>
			<Tr fontSize='1vw'>
				<Td>{item["msku"]}</Td>
				<Td>{item["productName"]}</Td>
				<Td>{item["asin"]}</Td>
				<Td>{item["fnsku"]}</Td>
				<Td>{item["shipped"]}</Td>
				<Td>{item["received"]}</Td>
				<Td>{item["discrepancy"]}</Td>
				<Td></Td>
			</Tr>
		</>
	);
};

export default ShipTableItemDetail;
