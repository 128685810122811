import { Flex } from "@chakra-ui/react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.defaults.color = "rgba(0, 0, 0, 1)";
ChartJS.defaults.plugins.legend.labels.usePointStyle = true;
ChartJS.defaults.plugins.legend.labels.pointStyle = "rectRounded";

const DoughnutChartReimb = () => {
	const options = {
		responsive: true,
		plugins: {
			title: {
				display: false,
				text: "Reimbursed Value (Store)",
				position: "top",
				align: "start",
			},
			legend: {
				position: "bottom",
				align: "start",
				labels: {
					font: { size: 10 },
					padding: 12,
				},
			},
		},
	};

	const { doughnutChartReimbData } = useSelector((state) => state.userCases);
	return (
		<Flex
			className='conclude-tile component-dashboard'
			w={{ base: "48%", md: "48%", lg: "48%" }}
			aspectRatio='1/1.33'
			alignItems='center'
			justifyContent='space-around'
			bgColor='white'
			flexDir='column'
		>
			<Flex className='conclude-tile' w='100%' alignItems='center' paddingLeft='0.5vw' fontSize='1vw' fontWeight='bold'>
				Reimbursed Value (Store)
			</Flex>
			<Doughnut options={options} data={doughnutChartReimbData} />
		</Flex>
	);
};

export default DoughnutChartReimb;
