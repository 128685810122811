import "../Styles/landingScreen.css";
import { Flex, Heading, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";

const ClientCredit = () => {
	const { credit, referralId } = useSelector((state) => state.user.userInfo);

	return (
		<Flex w='85vw' minH='100vh' flexDir={{ base: "column", md: "row" }}>
			{/* Left Part */}
			<Flex w={{ base: "100%", md: "50%" }} h={{ base: "30%", md: "100%" }} justifyContent='center' alignItems='center'>
				<Text fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}>
					Woohooooo! You have{" "}
					<Text as='span' color='#4FD1C5'>
						${credit}
					</Text>{" "}
					credits
				</Text>
			</Flex>
			{/* Right Part */}
			<Flex
				className='landing-body'
				w={{ base: "100%", md: "50%" }}
				h={{ base: "70%", md: "100%" }}
				flexDir='column'
				alignItems='center'
				justifyContent='center'
				p='2em'
			>
				<Heading color='white' fontSize={{ sm: "1.1rem", md: "1.2rem", lg: "1.4rem", xl: "1.6rem", "2xl": "1.9rem" }}>
					Crave for more credits?
				</Heading>
				<Text
					marginTop='1em'
					marginBottom='1em'
					color='white'
					fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
				>
					Refer us a client, and both you and the client will receive $300 free-auditing credits.
				</Text>
				<Text color='white' fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}>
					Have your friend enter your referral code upon adding stores into their accounts. The credits will then be
					added into your account, and be applied in your next month statement.
				</Text>
				<Text
					marginBottom='1em'
					fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
				></Text>
				<Text color='white' fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}>
					Your referral code:{" "}
					<Text
						as='span'
						fontWeight='bold'
						fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
					>
						{referralId}
					</Text>
				</Text>
			</Flex>
		</Flex>
	);
};

export default ClientCredit;
