import React from "react";
import { Button } from "@chakra-ui/react";
import { useState } from "react";
import axios from "axios";

const UserAdminScreen = () => {
	const [files, setFiles] = useState(null);
	const [fileNames, setFileNames] = useState(null);
	const [msg, setMsg] = useState(null);

	function handleUpload() {
		if (!files) {
			setMsg("No file selected");
			return;
		}
		const fd = new FormData();
		for (let index = 0; index < files.length; index++) {
			fd.append(`files${index + 1}`, files[index]);
		}
		setMsg("Uploading...");
		axios
			.post("/api/portal/file-upload", fd)
			.then((res) => {
				setMsg("Upload successful");
			})
			.catch((err) => {
				setMsg("Upload failed");
			});
	}

	return (
		<>
			<input
				onChange={(e) => {
					setFileNames(null);
					const fileInfo = [];
					for (let index = 0; index < e.target.files.length; index++) {
						fileInfo.push(e.target.files[index].name);
					}
					setFiles(e.target.files);
					setFileNames(fileInfo);
				}}
				type='file'
				accept='application/pdf'
				multiple
			/>
			{fileNames &&
				fileNames.map((fileName) => {
					return <span>{fileName}</span>;
				})}
			<Button
				marginTop={{ base: "1vw", md: "2vw" }}
				marginBottom={{ base: "0.5vw", md: "0.5vw" }}
				colorScheme='teal'
				w='20%'
				size='xs'
				onClick={handleUpload}
			>
				Upload
			</Button>
			{msg && <span style={{ color: "rgba(128, 128, 128, 0.8)", fontSize: "1.5vw" }}>{msg}</span>}
			{/* <embed src='../../upload/Invoice-TBIZOXX1GGVJ-0001.pdf' type='application/pdf' width='100%' height='500px' /> */}
		</>
	);
};

export default UserAdminScreen;
