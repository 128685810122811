import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Divider,
	Spinner,
	Stack,
	TableContainer,
	Table,
	Tbody,
	Th,
	Thead,
	Tr,
	Wrap,
	Flex,
	Heading,
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	FormControl,
	FormLabel,
	Input,
	ModalFooter,
	useDisclosure,
	Select,
	Icon,
	Text,
} from "@chakra-ui/react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ImAmazon } from "react-icons/im";
import { addStore } from "../redux/actions/userActions";
import StoreTableItem from "./StoreTableItem";

const ClientStoreDetail = () => {
	const dispatch = useDispatch();
	const { isOpen, onOpen, onClose } = useDisclosure();

	const initialRef = useRef(null);
	const finalRef = useRef(null);

	const {
		loading,
		error,
		pagination: { totalPages, currentPage },
	} = useSelector((state) => state.event);

	const { _id, store } = useSelector((state) => state.user.userInfo);

	const submitForm = () => {
		const storeName = document.getElementById("storeName").value;
		const selectMarket = document.getElementById("selectMarket").value;
		const currency = document.getElementById("currency").value;
		const clientId = document.getElementById("clientId").value;
		const clientSecret = document.getElementById("clientSecret").value;
		const refreshToken = document.getElementById("refreshToken").value;
		const referralCode = document.getElementById("referralCode").value;
		dispatch(addStore(_id, storeName, selectMarket, currency, clientId, clientSecret, refreshToken, referralCode));
	};

	return (
		// The overall wrapper
		<Flex
			className='component-dashboard'
			w={{ base: "96%", md: "97%" }}
			marginTop={{ base: "1em", md: "1em", lg: "1em" }}
			borderRadius='1em'
			bgColor='white'
		>
			{error && (
				<Alert status='error'>
					<AlertIcon />
					<AlertTitle>Oops!</AlertTitle>
					<AlertDescription>{error}</AlertDescription>
				</Alert>
			)}
			{loading ? (
				<Wrap justify='center'>
					<Stack direction='row' spacing='4'>
						<Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='teal.500' size='xl' />
					</Stack>
				</Wrap>
			) : (
				<Flex
					alignItems='center'
					justifyContent='flex-start'
					w='100%'
					flexDir='column'
					borderRadius='1vw'
					overflow='auto'
				>
					{/* Row 1 */}
					<Flex
						w='95%'
						h='5em'
						fontSize='1.2vw'
						fontWeight='bold'
						alignItems='center'
						borderRadius='1vw'
						justifyContent='space-between'
						paddingLeft='0.5em'
						paddingRight='0.5em'
					>
						<Heading fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}>
							Store Detail
						</Heading>
						<Button
							onClick={onOpen}
							variant='outline'
							fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
						>
							Add New Store
						</Button>
					</Flex>
					<Divider />
					{/* Row 2 */}
					<Flex alignItems='center' justifyContent='center' w='100%' overflow='auto'>
						<TableContainer>
							<Table variant='simple' h='20vh' overflow='auto'>
								<Thead>
									<Tr>
										<Th
											fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
											fontFamily='Arial'
										>
											Store Name
										</Th>
										<Th
											fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
											fontFamily='Arial'
										>
											Marketplace
										</Th>
										<Th
											fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
											fontFamily='Arial'
										>
											Client Identifier
										</Th>
										<Th
											fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
											fontFamily='Arial'
										>
											Client Secret
										</Th>
										<Th
											fontSize={{ sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
											fontFamily='Arial'
										>
											Refresh Token
										</Th>
									</Tr>
								</Thead>
								<Tbody>
									{store.length > 0 &&
										store.map((singleStoreInfo) => (
											<StoreTableItem key={singleStoreInfo._id} singleStore={singleStoreInfo} />
										))}
								</Tbody>
							</Table>
						</TableContainer>
					</Flex>
				</Flex>
			)}

			<Modal
				initialFocusRef={initialRef}
				finalFocusRef={finalRef}
				isOpen={isOpen}
				onClose={onClose}
				scrollBehavior='inside'
			>
				<ModalOverlay />
				<ModalContent as='form'>
					<ModalHeader>Add New Store</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>
						<FormControl isRequired>
							<FormLabel>Store Name</FormLabel>
							<Input ref={initialRef} placeholder='Store name' id='storeName' />
						</FormControl>

						<FormControl isRequired mt={4}>
							<FormLabel>Marketplace</FormLabel>
							<Select placeholder='Select Marketplace' id='selectMarket'>
								<optgroup label='Americas'>
									<option value='United States'>United States</option>
									<option value='Canada'>Canada</option>
									<option value='Mexico'>Mexico</option>
									<option value='Brazil'>Brazil</option>
								</optgroup>

								<optgroup label='Europe'>
									<option value='Germany'>Germany</option>
									<option value='Spain'>Spain</option>
									<option value='France'>France</option>
									<option value='Italy'>Italy</option>
									<option value='Netherlands'>Netherlands</option>
									<option value='Poland'>Poland</option>
									<option value='Sweden'>Sweden</option>
									<option value='United Kingdom'>United Kingdom</option>
									<option value='Turkey'>Turkey</option>
								</optgroup>

								<optgroup label='Asia-Pacific'>
									<option value='Australia'>Australia</option>
									<option value='Japan'>Japan</option>
									<option value='India'>India</option>
									<option value='Singapore'>Singapore</option>
								</optgroup>

								<optgroup label='Middle East'>
									<option value='United Arab Emirates'>United Arab Emirates</option>
									<option value='Egypt'>Egypt</option>
									<option value='Saudi Arabia'>Saudi Arabia</option>
								</optgroup>
							</Select>
						</FormControl>

						<FormControl isRequired mt={4}>
							<FormLabel>Currency</FormLabel>
							<Select placeholder='Select Currency' id='currency'>
								<option value='USD'>U.S. Dollar </option>
								<option value='CD'>Canadian Dollar</option>
								<option value='MP'>Mexican Peso</option>
								<option value='PSB'>Pound Sterling (British) </option>
								<option value='ED'>Euro</option>
								<option value='AD'>Australian Dollar</option>
								<option value='JY'>Japanese Yen</option>
							</Select>
						</FormControl>

						<FormControl isRequired mt={4}>
							<FormLabel>Client Identifier</FormLabel>
							<Input ref={initialRef} placeholder='Client Identifier' id='clientId' />
						</FormControl>

						<FormControl isRequired mt={4}>
							<FormLabel>Client Secret</FormLabel>
							<Input ref={initialRef} placeholder='Client Secret' id='clientSecret' />
						</FormControl>

						<FormControl isRequired mt={4}>
							<FormLabel>Refresh Token</FormLabel>
							<Input ref={initialRef} placeholder='Refresh Token' id='refreshToken' />
						</FormControl>

						<FormControl mt={4}>
							<FormLabel>Referral Code</FormLabel>
							<Input ref={initialRef} placeholder='Referral Code' id='referralCode' />
						</FormControl>
					</ModalBody>

					<ModalFooter alignItems='center' justifyContent='center'>
						<Button
							id='submitBtn'
							margin='1em'
							paddingLeft='1em'
							paddingRight='1em'
							onClick={() => {
								submitForm();
								onClose();
							}}
							// isDisabled='true'
						>
							<Icon as={ImAmazon} marginRight='1em' />
							<Text fontSize={{ base: "0.5em", md: "0.6em", lg: "0.7em" }}>
								I authorize TinkerBoost to be my private developer
							</Text>
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Flex>
	);
};

export default ClientStoreDetail;
