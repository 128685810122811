import axios from 'axios';
import {
    setEventError,
    setPagiEvents,
    setPagination,
    setEvents,
} from '../slices/event.js';



export const getEvent = (clientEmail, eventType) => async (dispatch, getState) => {
    dispatch(setEventError(null))
    const { pagination: { eventsPerPage, currentPage, totalPages } } = getState().event;
    try {
        const config = { headers: { 'Content-Type': 'application/json' }, params: { 'email': clientEmail, 'eventType': eventType } };
        const { data } = await axios.get(`/api/admin/case-admin/event`, config);
        data.events.forEach((event, index) => {
            event.idx = index
            event.isSaved = 0
            event.eventStatus = ""
            event.caseID = ""
            event.quantityReimb = ""
            event.valueReimb = ""
            event.reimbID = ""
        });
        dispatch(setEvents(data.events));
        if (data.events.length > 0) {
            const newTotalPages = Math.ceil(data.events.length / eventsPerPage);
            const startIndex = (currentPage - 1) * eventsPerPage;
            const endIndex = startIndex + eventsPerPage;
            const paginatedEvents = data.events.slice(startIndex, endIndex);
            dispatch(setPagination({ eventsPerPage, currentPage, 'totalPages': newTotalPages }));
            dispatch(setPagiEvents(paginatedEvents));
        }
    } catch (error) {
        dispatch(
            setEventError(
                error.response && error.response.data
                    ? error.response.data
                    : error.message
                        ? error.message
                        : 'An unexpected error has occured. Please try again later.'
            )
        );

    }

}

export const getPagiEvents = (clickedPage) => async (dispatch, getState) => {
    const curEvents = getState().event.events;
    const { eventsPerPage, totalPages } = getState().event.pagination;
    const startIndex = (clickedPage - 1) * eventsPerPage;
    const endIndex = startIndex + eventsPerPage;
    const paginatedEvents = curEvents.slice(startIndex, endIndex);
    dispatch(setPagination({ eventsPerPage, 'currentPage': clickedPage, totalPages }));
    dispatch(setPagiEvents(paginatedEvents));
}