import { useSelector } from "react-redux";
import { Flex, useToast, Table, TableContainer, Thead, Tr, Th, Tbody, Text } from "@chakra-ui/react";
import PaymentBankDetail from "./PaymentBankDetail";
import PaymentCardDetail from "./PaymentCardDetail";

import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const PaymentMethods = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const toast = useToast();

	useEffect(() => {
		if (searchParams.get("setup_intent_client_secret")) {
			toast({
				description: "Your card was successfully saved.",
				status: "success",
				isClosable: true,
			});
		}
	}, [searchParams, toast]);

	const { stripeCreditCardInfo, stripeBankDebitInfo } = useSelector((state) => state.user);

	return (
		<Flex w='100%' h='100%' flexDir='column' padding='2rem' alignItems='center' justifyContent='space-evenly'>
			{stripeCreditCardInfo.length > 0 && (
				<Flex w='100%' margin='2rem' flexDir='column' alignItems='center' justifyContent='center'>
					<Text
						fontSize={{ base: "0.6rem", sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
						fontWeight='600'
					>
						Saved Card
					</Text>
					<TableContainer whiteSpace='wrap' marginTop={{ base: "15px", lg: "30px" }}>
						<Table size={{ base: "md", lg: "md" }}>
							<Thead>
								<Tr>
									<Th
										fontSize={{ base: "0.6rem", sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
									>
										Brand
									</Th>
									<Th
										fontSize={{ base: "0.6rem", sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
									>
										Expiration Month
									</Th>
									<Th
										fontSize={{ base: "0.6rem", sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
									>
										Expiration Year
									</Th>
									<Th
										fontSize={{ base: "0.6rem", sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
									>
										Last 4
									</Th>
								</Tr>
							</Thead>
							<Tbody>
								{stripeCreditCardInfo.length > 0 &&
									stripeCreditCardInfo.map((item) => <PaymentCardDetail key={item._id} item={item} />)}
							</Tbody>
						</Table>
					</TableContainer>
				</Flex>
			)}

			{stripeBankDebitInfo.length > 0 && (
				<Flex w='100%' margin='2rem' flexDir='column' alignItems='center' justifyContent='center'>
					<Text
						fontSize={{ base: "0.6rem", sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
						fontWeight='600'
					>
						Saved US Bank Account
					</Text>

					<TableContainer whiteSpace='wrap' marginTop='2rem'>
						<Table size={{ base: "md", lg: "lg" }}>
							<Thead>
								<Tr>
									<Th
										fontSize={{ base: "0.6rem", sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
									>
										Bank Name
									</Th>
									<Th
										fontSize={{ base: "0.6rem", sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
									>
										Account Type
									</Th>
									<Th
										fontSize={{ base: "0.6rem", sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
									>
										Routing Number
									</Th>
									<Th
										fontSize={{ base: "0.6rem", sm: "0.7rem", md: "0.8rem", lg: "1rem", xl: "1.2rem", "2xl": "1.5rem" }}
									>
										Last 4
									</Th>
								</Tr>
							</Thead>
							<Tbody>
								{stripeBankDebitInfo.length > 0 &&
									stripeBankDebitInfo.map((item) => <PaymentBankDetail key={item._id} item={item} />)}
							</Tbody>
						</Table>
					</TableContainer>
				</Flex>
			)}
		</Flex>
	);
};

export default PaymentMethods;
