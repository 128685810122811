import axios from "axios";
import { setStripeIntentSecret, setStripeCustomerSessionClientSecret, setStripeCreditCardInfo, setStripeBankDebitInfo, setStripeCustomerId, setFinantialInfoLoading } from '../slices/user.js';

export const getFinancialInfo = (name, email, stripeCustomerId) => async (dispatch, getState) => {
    try {
        const config = { headers: { 'Content-Type': 'application/json' }, params: { name, email, stripeCustomerId } };
        const { data } = await axios.get('/api/checkout/secret', config);
        dispatch(setStripeIntentSecret(data.clientSecret));
        dispatch(setStripeCustomerSessionClientSecret(data.customerSessionClientSecret));

        // Storing payment methods info and display them to the user.
        const creditCards = [];
        const bankDebits = [];
        if (data.paymentMethod.length > 0) {
            for (let index = 0; index < data.paymentMethod.length; index++) {
                const method = data.paymentMethod[index];
                if (method['type'] === 'card') {
                    creditCards.push(method)
                } else if (method['type'] === 'us_bank_account') {
                    bankDebits.push(method)
                }
            }
            dispatch(setStripeCreditCardInfo(creditCards));
            dispatch(setStripeBankDebitInfo(bankDebits));
        }

        if (data && !(stripeCustomerId === data.customerId)) {
            dispatch(setStripeCustomerId(data.customerId));
        }
        dispatch(setFinantialInfoLoading(false));
    } catch (error) {
        console.log(error)
    }
}