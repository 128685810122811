import { Flex, Divider } from "@chakra-ui/react";
import Footer from "./Footer.jsx";
import ClientStoreDetail from "./ClientStoreDetail.jsx";

const ClientStoreInfo = () => {
	return (
		// The Main Wrapper
		<Flex
			alignItems='center'
			justifyContent='flex-start'
			w='85vw'
			minH='50em'
			flexDir='column'
			borderRadius='1vw'
			paddingLeft='1vw'
			paddingRight='1vw'
			paddingTop='1vw'
			overflow='auto'
		>
			{/* The First Row - Store Info Part */}
			<Flex
				w='100%'
				alignItems='center'
				justifyContent='flex-start'
				borderRadius='1em'
				flexDir='column'
				marginBottom='6em'
				overflow='auto'
			>
				<ClientStoreDetail />
			</Flex>
			{/* The Second Row - Footer */}
			{/* <Flex w='100%' borderRadius='1vw' flexDir='column' alignItems='center' justifyContent='space-between'>
				<Divider border='0.01rem solid rgba(0, 0, 0, 0.1)' margin={{ base: "1em", md: "2em" }} />
				<Footer />
			</Flex> */}
		</Flex>
	);
};

export default ClientStoreInfo;
