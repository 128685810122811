import {
	IconButton,
	Flex,
	Heading,
	HStack,
	Icon,
	Text,
	useColorModeValue as mode,
	Image,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	useToast,
} from "@chakra-ui/react";
import { LuArrowUpRightSquare } from "react-icons/lu";
import { Link as ReactLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { PiUserCircle, PiUserCircleCheck } from "react-icons/pi";
import { HamburgerIcon, CloseIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { logout } from "../redux/actions/userActions";
import { setShowLogin } from "../redux/slices/user";
import { setShowSignup } from "../redux/slices/user";
import { FcGoogle } from "react-icons/fc";
import { googleLogout } from "@react-oauth/google";

const MainHeaderColor = () => {
	const toast = useToast();
	const dispatch = useDispatch();
	const logoutHandler = () => {
		googleLogout();
		dispatch(logout());
		toast({
			description: "You have been logged out.",
			status: "success",
			isClosable: "true",
		});
	};

	const { userInfo } = useSelector((state) => state.user);

	return (
		<Flex
			w='100%'
			h='100%'
			bgColor='rgba(0,0,0,1)'
			alignItems='center'
			justifyContent='space-between'
			p={{ base: "2em", md: "2em", lg: "2em" }}
		>
			<Flex alignItems='center' justifyContent='center' h='100%' as={ReactLink} to='/'>
				<Heading alignSelf='center' color='#4FD1C5' fontSize='1.3em' fontFamily='Arial' letterSpacing='tight'>
					TinkerBoost
				</Heading>
				<Flex marginLeft='0.3vmin' color='#4FD1C5'>
					<Icon as={LuArrowUpRightSquare} boxSize={{ base: "1.3em", md: "1.3em", lg: "1.3em" }} />
				</Flex>
			</Flex>
			<Flex>
				{/* <Flex marginRight='0.3vmin' fontSize='4vmin' color='white'>
					<Icon as={VscAccount} />
				</Flex> */}
				<Flex alignItems='center'>
					{userInfo ? (
						<Menu>
							<MenuButton rounded='full' variant='link' cursor='pointer' minW='0'>
								<HStack>
									{userInfo.googleImage ? (
										<Image borderRadius='full' boxSize='25px' src={userInfo.googleImage} referrerPolicy='no-referrer' />
									) : (
										<PiUserCircleCheck size='25' color='#4FD1C5' />
									)}
									<ChevronDownIcon color='#4FD1C5' />
								</HStack>
							</MenuButton>
							<MenuList>
								<HStack>
									<Text pl='3' fontWeight='550'>
										Hello! {userInfo.name}
									</Text>
									{userInfo.googleId && <FcGoogle />}
								</HStack>
								<MenuDivider />
								<MenuItem as={ReactLink} to='/portal/dashboard'>
									Portal
								</MenuItem>
								<MenuDivider />
								<MenuItem onClick={logoutHandler}>Sign out</MenuItem>
							</MenuList>
						</Menu>
					) : (
						<Menu>
							<MenuButton
								as={IconButton}
								variant='ghost'
								cursor='pointer'
								icon={<PiUserCircle size='25' color='#4FD1C5' />}
							/>
							<MenuList>
								<MenuItem
									as={ReactLink}
									to='/portal'
									p='2'
									fontWeight='400'
									variant='link'
									onClick={() => dispatch(setShowLogin(true))}
								>
									Sign in
								</MenuItem>
								<MenuDivider />
								<MenuItem
									as={ReactLink}
									to='/portal'
									p='2'
									fontWeight='400'
									variant='link'
									onClick={() => dispatch(setShowSignup(true))}
								>
									Sign up
								</MenuItem>
							</MenuList>
						</Menu>
					)}
				</Flex>
			</Flex>
		</Flex>
	);
};

export default MainHeaderColor;
