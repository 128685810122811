import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import AdminEventInboundNew from "./AdminEventInboundNew";
import AdminEventInboundDocRequired from "./AdminEventInboundDocRequired";
import AdminEventInboundDocUploaded from "./AdminEventInboundDocUploaded";

const AdminEventInbound = () => {
	return (
		<Tabs>
			<TabList>
				<Tab>New</Tab>
				<Tab>Doc Required</Tab>
				<Tab>Doc Uploaded</Tab>
			</TabList>

			<TabPanels>
				<TabPanel>
					<AdminEventInboundNew />
				</TabPanel>
				<TabPanel>
					<AdminEventInboundDocRequired />
				</TabPanel>
				<TabPanel>
					<AdminEventInboundDocUploaded />
				</TabPanel>
			</TabPanels>
		</Tabs>
	);
};

export default AdminEventInbound;
