import { TableContainer, Table, Tbody, Th, Thead, Tr, useToast, Flex, Wrap, Button, Icon } from "@chakra-ui/react";
import EventTableLostItem from "./EventTableLostItem";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { saveToDatabaseAction } from "../redux/actions/adminActions.js";
import { getEvent, getPagiEvents } from "../redux/actions/eventActions";
import { useDispatch, useSelector } from "react-redux";

const AdminEventLost = ({ client, eventType }) => {
	const dispatch = useDispatch();
	const {
		pagiEvents,
		pagination: { totalPages, currentPage },
	} = useSelector((state) => state.event);
	const { toSaveCases, toRemoveEvents } = useSelector((state) => state.adminCase);

	const saveToDatabase = () => {
		dispatch(saveToDatabaseAction(Object.values(toSaveCases), Object.keys(toRemoveEvents), client, eventType));
	};

	const paginationButtonClick = (curPage) => {
		dispatch(getPagiEvents(curPage));
	};

	// Generate paginated buttons in accordance with users input.
	const genPagiBtns = () => {
		const allLeadBtns = () => {
			const buttons = [];
			for (let i = 1; i < currentPage; i++) {
				buttons.push(
					<Button key={i} onClick={() => paginationButtonClick(i)} size='sm'>
						{i}
					</Button>
				);
			}
			return buttons;
		};

		const allLastBtns = () => {
			const btns = [];
			for (let i = currentPage + 1; i <= totalPages; i++) {
				btns.push(
					<Button key={i} onClick={() => paginationButtonClick(i)} size='sm'>
						{i}
					</Button>
				);
			}
			return btns;
		};

		return (
			<>
				{currentPage - 3 > 1 ? (
					<>
						<Button key={1} onClick={() => paginationButtonClick(1)} size='sm'>
							1
						</Button>
						...
						<Button key={currentPage - 2} onClick={() => paginationButtonClick(currentPage - 2)} size='sm'>
							{currentPage - 2}
						</Button>
						<Button key={currentPage - 1} onClick={() => paginationButtonClick(currentPage - 1)} size='sm'>
							{currentPage - 1}
						</Button>
					</>
				) : (
					allLeadBtns()
				)}
				<Button colorScheme='teal' key={currentPage} onClick={() => paginationButtonClick(currentPage)} size='sm'>
					{currentPage}
				</Button>
				{currentPage + 3 < totalPages ? (
					<>
						<Button key={currentPage + 1} onClick={() => paginationButtonClick(currentPage + 1)} size='sm'>
							{currentPage + 1}
						</Button>
						<Button key={currentPage + 2} onClick={() => paginationButtonClick(currentPage + 2)} size='sm'>
							{currentPage + 2}
						</Button>
						...
						<Button key={totalPages} onClick={() => paginationButtonClick(totalPages)} size='sm'>
							{totalPages}
						</Button>
					</>
				) : (
					allLastBtns()
				)}
			</>
		);
	};

	return (
		<Flex flexDirection='column' alignItems='center' justifyContent='center' w='100%' overflow='auto'>
			<TableContainer>
				<Table variant='simple' size='sm'>
					<Thead>
						<Tr>
							<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>STORE</Th>
							<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>FNSKU</Th>
							<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>STATUS</Th>
							<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>CASE ID</Th>
							<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>Quantity</Th>
							<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>Value</Th>
							<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>Reimbursement ID</Th>
						</Tr>
					</Thead>
					<Tbody>
						{pagiEvents.length > 0 &&
							pagiEvents.map((singleEvent) => (
								<EventTableLostItem key={singleEvent._id} singleEvent={singleEvent} eventType={eventType} />
							))}
					</Tbody>
				</Table>
			</TableContainer>

			<Flex w='100%' alignItems='center' justifyContent='center' borderRadius='1vw'>
				<Wrap spacing='1vw' justify='center' p='1vw'>
					<Button
						id='casePagiLeftArrow'
						onClick={() => paginationButtonClick(currentPage - 1 > 0 ? currentPage - 1 : 1)}
						size='sm'
					>
						<Icon className='nav-items-icon' as={MdKeyboardArrowLeft} boxSize={3} />
					</Button>
					{genPagiBtns()}
					<Button
						id='casePagiRightArrow'
						onClick={() => paginationButtonClick(currentPage + 1 < totalPages ? currentPage + 1 : totalPages)}
						size='sm'
					>
						<Icon className='nav-items-icon' as={MdKeyboardArrowRight} boxSize={3} />
					</Button>
				</Wrap>
			</Flex>
			<Flex
				alignItems='center'
				justifyContent='center'
				w='90%'
				overflow='auto'
				marginTop={{ base: "4em", md: "4em" }}
				marginBottom={{ base: "2em", md: "2em" }}
			>
				<Button
					size={{ base: "sm", md: "sm", lg: "sm" }}
					onClick={saveToDatabase}
					variant='outline'
					colorScheme='green'
					isDisabled={Object.keys(toRemoveEvents).length === 0 ? true : false}
				>
					Save to Client's Database
				</Button>
			</Flex>
		</Flex>
	);
};

export default AdminEventLost;
